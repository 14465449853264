import React from 'react'

function BackButton(props) {

        return(
        		
		            <div  onClick={() => props.backHandler()} className="ga-button ga-back">
		                Back
		            </div>
	            
	        
        );


}

export default BackButton;
