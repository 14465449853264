import React from 'react'

function HorrorCell(props) {

    var stars = [];
    for (var c=1; c<=5; c++)
    {
        if (props.rating >= c)
        {
            stars.push(<div className="full-star" ></div>);
        } else {
            stars.push(<div className="empty-star" ></div>);

        }
    }

    return(
    	<div className="cell small-6 medium-4 large-3">
        <div className="horror-cell" onClick={props.clickHandler}>

	                <div class="horror-image">

                    <img src={props.image} alt="Horror Submission" /></div>
            <div className="horror-rating">{stars}

            </div>
            <div className="name">{props.user}</div>
            <div className="loc">{props.location}</div>
        </div>
        </div>
    );
}

export default HorrorCell;
