import React from 'react'
import BackButton from "../common/BackButton"
import { API } from '../../api/APIClient'
import Axios from 'axios'
import PDFItem from "./PDFItem"

class ReferencePDFs extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            pdfs: [],
            isLoading: true,
            debug: {}
        }
    }



    getPDFList = () => {

        Axios
            .get(API.toolbox.getRefPDFs)
            .then((res) => {
                this.setState({
                    debug: res
                });
                if (res.data.success) {

                    this.setState({
                        pdfs: res.data.data.map((pdf, c) => {

                            return <PDFItem title={pdf.title}
                                pdfUrl={API.BASE_URL + API.toolbox.outputPDF + pdf.id} />;
                        })
                    })
                }
            })
    }
    componentDidMount() {

        this.getPDFList();

    }


    render() {

        return (
            <div className="main-wrap">
                <div className="cell small-12 header">
                    <BackButton backHandler={this.props.backHandler} />
                    <h1 className="head-title toolbox">Toolbox > Reference PDFs</h1>
                </div>
                <div className="cell small-12 refpdfs">
                    {this.state.pdfs}
                </div>
            </div>
        );
    }
}

export default ReferencePDFs;
