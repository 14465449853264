import React from 'react'

function Iframe(props) {

    return(
        <div>
            <iframe title="iframe" src={props.src} height={props.height} width={props.width}/>
        </div>
    );
}

export default Iframe;
