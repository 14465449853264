import React from 'react'
import BackButton from "../common/BackButton"
import VideoCell from "./VideoCell"
import YouTube from 'react-youtube';
import { API } from '../../api/APIClient'
import Axios from 'axios'

class Videos extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            channels: [],
            currentVideo: null,
            isLoading: true,
            postcode: '',
            videoPage: 1
        }
    }


    showVideo(videoID)
    {
        const opts = {
            height: '390',
            width: '640'
        };
        this.setState({

            currentVideo : <YouTube videoId={videoID} opts={opts} />

        });
    }


    backClicked () {

        if (this.state.currentVideo)
        {
            this.setState({currentVideo : null});
        } else {
            this.props.backHandler();
        }
    }
    getVideos = () => {

        Axios
            .get(API.toolbox.getVideos + this.state.videoPage)
            .then((res) => {
                this.setState({
                    debug: res
                });
                if (res.data.success) {

                    const newChannels = res.data.data.map((channel) => {

                        const vids = channel.videos.map((video) => {

                            return <VideoCell showVideoHandler={(videoID) => this.showVideo(videoID)} thumbnailUrl={video.thumbnailUrl} title={video.title} videoID={video.videoId}/>

                        });
                        return  <div className="cell small-12 channel">
                            <div className="channel-title">{channel.title}</div>
                            <div className="channel-wrap">
                                <div className="channel-cell">
                                                {vids}
                                </div>
                            </div>
                        </div>;
                    });

                    if (newChannels.length > 0)
                    {
                        this.setState({
                            channels:this.state.channels.concat(newChannels),
                            videoPage: this.state.videoPage + 1
                        }, () => this.getVideos());

                    }


                }
            })
    }
    componentDidMount() {

        this.getVideos();

    }


    render() {

        return (
            <div className="main-wrap">
                <div className="cell small-12 header">
                <BackButton backHandler={() => this.backClicked() } />
                <h1 className="head-title toolbox">Toolbox > Videos</h1>
                </div>
                <div className="cell small-12">
                {this.state.currentVideo ? this.state.currentVideo : this.state.channels}
                </div>

            </div>
        );
    }
}

export default Videos;
