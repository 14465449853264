import React from 'react'
import BackButton from "../common/BackButton"
import { API } from '../../api/APIClient'
import Axios from 'axios'
import ContactItem from "./ContactItem"

class Contacts extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            contacts: [],
            isLoading: true,
            debug: {}
        }
    }



    getContactList = () => {

        const formData = new FormData();
        formData.append('count', 1000);
        formData.append('typeId', 1);

        Axios
            .post(API.toolbox.getContacts, formData)
            .then((res) => {
                this.setState({
                    debug: res
                });
                if (res.data.success) {

                    this.setState({
                        contacts: res.data.data.map((contact, c) => {

                            return <ContactItem title={contact.title}
                                telephone={contact.telephone}
                            />;
                        })
                    })
                }
            })

    }
    componentDidMount() {

        this.getContactList();

    }


    render() {

        return (
            <div className="main-wrap contacts">
                <div className="cell small-12 header">
                    <BackButton backHandler={this.props.backHandler} />
                    <h1 className="head-title toolbox">Toolbox > Contacts</h1>
                </div>
                <div className="cell small-12 contactlist">
                {this.state.contacts}
                </div>
            </div>
        );
    }
}

export default Contacts;
