import React from 'react'
import PostCell from './PostCell'
import HorrorCell from './HorrorCell'
import VideoCell from './VideoCell'
import KnowledgeCell from './KnowledgeCell'
import { API } from '../../api/APIClient'
import Axios from 'axios'
import PostComments from './PostComments'
import NewPost from './NewPost'
import MasonryLayout from 'react-masonry-layout'
import HorrorDetail from '../horrorshow/HorrorDetail'
import YouTube from 'react-youtube';
import Notifications from './Notifications'
import BackButton from "../common/BackButton"
import Lightbox from 'lightbox-react';
import 'lightbox-react/style.css';
import refresh from '../../assets/img/refresh.png'
import bell from '../../assets/img/belln.svg'


class BreaktimePosts extends React.Component {

    masonry = null;
    // getBricksInstance = null;

    constructor(props) {
        super(props);



        this.SCREENS = {
            HOME: "home",
            COMMENTS: "comments",
            NEW_POST: "newpost",
            HORROR_DETAIL: "horror",
            NOTIFICATIONS: "notifs",
            VIDEO : "vid"
        };

        this.state = {
            currentScreen: this.SCREENS.HOME,
            navStack: [],
            posts: [],
            infLoading: false,
            infEnd: false,
            perPage: 30,
            lastPostId: -1,
            lastPostDate:null,
            subscribedIDs: null,
            lightboxOpen: false,
            imageIDX:0,
            unreadCount: 0
        };

    }



    reload()
    {
        this.setState({
            posts: [],
            infLoading: false,
            infEnd: false,
            perPage: 10,
            lastPostId: -1,
            lastPostDate:null
        }, function() {
            this.getPosts()
        });
    }



    postClicked(post)
    {
        this.props.showMenuHandler(false);
        const oldScreen = this.state.currentScreen;
        const navStack = this.state.navStack.concat([oldScreen])
        this.setState({
            currentScreen: this.SCREENS.COMMENTS,
            navStack: navStack,
            postToShow: post
        });
    }

    horrorCellClicked(horror)
    {
        this.props.showMenuHandler(false);
        const oldScreen = this.state.currentScreen;
        const navStack = this.state.navStack.concat([oldScreen])
        this.setState({
            currentScreen: this.SCREENS.HORROR_DETAIL,
            navStack: navStack,
            horrorToShow: horror
        });
    }

    videoCellClicked(vid)
    {
        this.props.showMenuHandler(false);
        const oldScreen = this.state.currentScreen;
        const navStack = this.state.navStack.concat([oldScreen])
        this.setState({
            currentScreen: this.SCREENS.VIDEO,
            navStack: navStack,
            videoToShow: vid
        });
    }




    subPostClicked(postID, shouldSub)
    {
        var APIToCall = "";
        if (shouldSub)
        {
            APIToCall = API.breakTime.subscribeToPost
        } else {
            APIToCall = API.breakTime.unsubscribeFromPost
        }

        const formData = new FormData();
        formData.append('postId', postID);

        Axios
            .post(APIToCall, formData)

    }

    imageClick(images)
    {
        this.setState({ligtboxImages: images, lightboxOpen:true})
    }


    likeClicked(postID)
    {
        var APIToCall =  API.breakTime.toggleLike + postID

        Axios
            .get(APIToCall)
    }


    getPosts = () => {


        if (this.state.lastPostId === null)
        {
            this.setState({infEnd : true})
            return;
        }
        this.setState({infLoading : true})
        const formData = new FormData();

        formData.append('showVids', 1);

        if (this.state.lastPostId > 0)
        {
            formData.append('afterId', this.state.lastPostId);
        }
        if (this.state.lastPostDate !== null)
        {
            formData.append('lastDate', this.state.lastPostDate);

        }

        Axios
            .post(API.breakTime.getPosts, formData)
            .then((res) => {

                if (res.data.success) {

                    var LAPD = null;
                    const newPosts = res.data.data.map((post, c) => {

                        const isSubbed = this.state.subscribedIDs.includes(post.id);

                        if (post.type === "BREAKTIME")
                        {
                            LAPD = post.dateCreated;
                            return <PostCell isSubbed={isSubbed}
                                clickHandler={() => this.postClicked(post)}
                                subHandler={(id,sub) => this.subPostClicked(id,sub)}
                                likeHandler={(id) => this.likeClicked(id)}
                                imageHandler={(imgs) => this.imageClick(imgs)}
                                post={post}
                            />;
                        } else if (post.type === "KNOWLEDGE") {
                            return <KnowledgeCell
                                post={post}
                            />;
                        }else if (post.type === "HORROR") {
                            return <HorrorCell clickHandler={() => this.horrorCellClicked(post)} location={post.location}
                                rating={post.rating}
                                image={post.image.thumb}
                                user={post.givenName + " " + post.familyName}
                            />;
                        }else if (post.type === "VIDEO") {
                            return <VideoCell clickHandler={() => this.videoCellClicked(post)}
                                title={post.title}
                                image={post.thumbnailUrl}
                            />;
                        } else {
                            return <div>Unknown Post Type</div>
                        }


                    });
                    this.setState({
                        posts: this.state.posts.concat(newPosts),
                        lastPostId: res.data.lastPostId,
                        lastPostDate: LAPD,
                        infLoading : false
                    })
                }
            })

    }


    getSubscribedIDs = () => {

        Axios
            .get(API.breakTime.getSubscribedPostIDs)
            .then((res) => {

                if (res.data.success) {

                    this.setState({
                        subscribedIDs: res.data.data

                    })
                }
                this.getPosts();

            })
    }




    getUnreadCount = () => {

        Axios
            .get(API.breakTime.getUreadCount)
            .then((res) => {

                if (res.data.success) {

                    this.setState({
                        unreadCount: res.data.data.unread

                    })
                }

            })
    }



    newPostClicked()
    {
        //if (this.props.user.state == 2) //trial user
        //{
        //    alert("You must be a paid-subscription user to create Breaktime posts.");

        //} else {
            this.props.showMenuHandler(false);
            const oldScreen = this.state.currentScreen;
            const navStack = this.state.navStack.concat([oldScreen])
            this.setState({
                currentScreen: this.SCREENS.NEW_POST,
                navStack: navStack
            });
        //}

    }

    notificationsClicked()
    {

            this.props.showMenuHandler(false);
            const oldScreen = this.state.currentScreen;
            const navStack = this.state.navStack.concat([oldScreen])
            this.setState({
                currentScreen: this.SCREENS.NOTIFICATIONS,
                navStack: navStack
            });

    }

    componentDidMount() {

        window.addEventListener("resize", this.refreshGrid);
        this.getSubscribedIDs();
        this.props.showMenuHandler(true);
        this.getUnreadCount();
    }


    componentWillUnmount() {
        window.removeEventListener("resize", this.refreshGrid);
    }

    refreshGrid = () => {
        if (this.masonry != null)
        {
            const grid = this.masonry.getBricksInstance();
            grid.pack();
        }
    }


    handleBackClick ()
    {
        const navStack = this.state.navStack.slice();

        if (navStack.length > 1)
        {
            this.props.showMenuHandler(false);
        } else {
            this.props.showMenuHandler(true);
            this.getUnreadCount();
        }
        const backScreen = navStack[navStack.length - 1];

        this.setState({
            currentScreen: backScreen,
            navStack: navStack.slice(0,navStack.length - 1)
        });
    }


    reloadCurrentPost()
    {
        const formData = new FormData();
        formData.append('postId', this.state.postToShow.id);

        Axios
            .post(API.breakTime.getPost, formData)
            .then((res) => {

                if (res.data.success) {

                    this.setState({
                        postToShow : res.data.data
                    })
                }
            })
    }



    render() {

        switch (this.state.currentScreen) {

            case this.SCREENS.HOME :

                let nmp = <span className="nmp">No more posts</span>

                var unread = <div></div>;

                if (this.state.unreadCount > 0)
                {
                    unread= <div className="notificationsBadge">{this.state.unreadCount}</div>
                }

                return(
                    <div className="main-wrap">
                        <div className="cell small-12 header">
                            <h1 className="head-title breaktime">Break Time</h1>
                            <div onClick={() => this.notificationsClicked()} className="ga-button bln"><img src={bell} height="30" />{unread}</div>
                            <div onClick={() => this.newPostClicked()} className="ga-button">Create new post</div>
                            <div onClick={() => window.location.reload()} className="ga-button refresher"><img src={refresh} height="25" /></div>


                        </div>
                        <div className="clearfix"></div>
                        <div className="cell small-12">
                            <MasonryLayout
                                id="masonry-layout"
                                infiniteScroll={this.getPosts}
                                infiniteScrollLoading={this.state.infLoading}
                                infiniteScrollEnd={this.state.infEnd}
                                infiniteScrollEndIndicator={nmp}
                                infiniteScrollSpinner="Loading..."
                            ref={instance => this.masonry = instance}
                            sizes={[ { columns: 1, gutter: 20 }, { mq: '640px', columns: 2, gutter: 20 }, { mq: '1280px', columns: 3, gutter: 20 } ]}
                            >

                                {this.state.posts}

                        </MasonryLayout>

                    </div>
                        {this.state.lightboxOpen && <Lightbox
                            mainSrc={this.state.ligtboxImages[this.state.imageIDX]}
                            nextSrc={this.state.ligtboxImages[(this.state.imageIDX + 1) % this.state.ligtboxImages.length]}
                            prevSrc={this.state.ligtboxImages[(this.state.imageIDX + this.state.ligtboxImages.length - 1) % this.state.ligtboxImages.length]}
                            onCloseRequest={() => this.setState({ lightboxOpen: false })}
                            onMovePrevRequest={() =>
                                this.setState({
                                    imageIDX: (this.state.imageIDX + this.state.ligtboxImages.length - 1) % this.state.ligtboxImages.length
                                })
                                }
                            onMoveNextRequest={() =>
                                this.setState({
                                    imageIDX: (this.state.imageIDX + 1) % this.state.ligtboxImages.length
                                })
                                }
                        />}
                    </div>

            );
            case this.SCREENS.COMMENTS :
                return(<PostComments post={this.state.postToShow}
                    backHandler={() => this.handleBackClick()}
                    user={this.props.user}
                    likeHandler={(postID) => this.likeClicked(postID)}
                    refreshHandler={() => this.reloadCurrentPost()}
                />);
            case this.SCREENS.NEW_POST :
                return(<NewPost backHandler={() => this.handleBackClick()}  reloadHandler={() => this.reload()} />);
            case this.SCREENS.HORROR_DETAIL :
                return(<HorrorDetail horror={this.state.horrorToShow} backHandler={() => this.handleBackClick()}  />);
            case this.SCREENS.NOTIFICATIONS :
                return(<Notifications backHandler={() => this.handleBackClick()}
                    user={this.props.user}
                />);
            case this.SCREENS.VIDEO :
                const opts = {
                    height: '390',
                    width: '640'
                };
                return (

                <div className="main-wrap">
                    <div className="cell small-12 header">
                        <BackButton backHandler={() => this.handleBackClick() } />
                        <h1 className="head-title">Video</h1>
                    </div>
                    <div className="cell small-12">
                        <YouTube videoId={this.state.videoToShow.videoId} opts={opts} />
                    </div>

                </div>);

            default :
                return(<div>unknown screen</div>);
        }
    }


}

export default BreaktimePosts;
