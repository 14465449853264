import React from 'react'
import PostCell from './PostCell'
import { API } from '../../api/APIClient'
import Axios from 'axios'
import PostComments from './PostComments'
import NewPost from './NewPost'
import MasonryLayout from 'react-masonry-layout'
import Lightbox from 'lightbox-react';
import 'lightbox-react/style.css';

class FaultFindingPosts extends React.Component {

	masonry = null;
	// getBricksInstance = null;

    constructor(props) {
        super(props);

        this.SCREENS = {
            HOME: "home",
            COMMENTS: "comments",
            NEW_POST: "newpost"

        };
        let openPost = null
        if (this.props.location.state !== undefined && this.props.location.state.openPost !== undefined)
        {
            //alert("opening post "+this.props.location.state.openPost);
            openPost = this.props.location.state.openPost
        }
        this.state = {
            currentScreen: this.SCREENS.HOME,
            navStack: [],
            posts: [],
            infLoading: false,
            infEnd: false,
            perPage: 10,
            lastPostId: -1,
            subscribedIDs: null,
            applianceType: -1,
            applianceBrand: -1,
            appliance: -1,
            searchText: "",
            openPost: openPost,
            showingFilters: true,
            lightboxOpen: false,
            imageIDX:0,
            subscription: null
        };

        this.handleChange = this.handleChange.bind(this);

    }

    reload()
    {
        this.setState({
            posts: [],
            infLoading: false,
            infEnd: false,
            perPage: 10,
            lastPostId: -1
        }, function() {
            this.getPosts()
        });
    }


    getSubInfo()
    {
        Axios
            .get(API.user.getSubscriptionData)
            .then((res) => {
                this.setState({
                    debug: res
                });
                if (res.data.success) {

                    this.setState({
                        subscription: res.data.data

                    })
                }
            })
    }


    postClicked(post)
    {
        //if (this.state.subscription === null || this.state.subscription.hasValidGasCard === true)
        //{
            this.props.showMenuHandler(false);
            const oldScreen = this.state.currentScreen;
            const navStack = this.state.navStack.concat([oldScreen])
            this.setState({
                currentScreen: this.SCREENS.COMMENTS,
                navStack: navStack,
                postToShow: post
            });
        //} else {

         //   alert("No valid gas safe ID card found, please upload one via the app or email your GS number to support@gasapp.co.uk to post and view answers.");
        //}

    }


    subPostClicked(postID, shouldSub)
    {
        var APIToCall = "";
        if (shouldSub)
        {
            APIToCall = API.faultFinder.subscribeToPost
        } else {
            APIToCall = API.faultFinder.unsubscribeFromPost
        }

        const formData = new FormData();
        formData.append('postId', postID);

        Axios
            .post(APIToCall, formData)

    }

    imageClick(images)
    {
        this.setState({ligtboxImages: images, lightboxOpen:true})
    }

    reloadCurrentPost()
    {
        const formData = new FormData();
        formData.append('postId', this.state.postToShow.id);

        Axios
            .post(API.faultFinder.getPost, formData)
            .then((res) => {

                if (res.data.success) {

                    this.setState({
                        postToShow : res.data.data
                    })
                }
            })
    }



    openPostOnLoad(){

        const formData = new FormData();
        formData.append('postId', this.state.openPost);

        Axios
            .post(API.faultFinder.getPost, formData)
            .then((res) => {

                if (res.data.success) {

                    this.postClicked(res.data.data)

                    this.setState({
                        openPost : null
                    })
                }
            })

    }

    getPosts = () => {


        if (this.state.lastPostId === null)
        {
            this.setState({infEnd : true})
            return;
        }
        this.setState({infLoading : true})
        const formData = new FormData();

        if (this.state.showingFilters)
        {
            if (this.state.applianceType > 0)
            {
                formData.append('applianceTypeID', this.state.applianceType);
            }
            if (this.state.applianceBrand > 0) {
                formData.append('applianceMakeID', this.state.applianceBrand);
            }
            if (this.state.appliance > 0) {
                formData.append('applianceModelID', this.state.appliance);
            }
            if (this.state.searchText.length > 0) {
                formData.append('searchText', this.state.searchText);
            }

        }

        if (this.state.lastPostId > 0)
        {
            formData.append('afterId', this.state.lastPostId);
        }

        Axios
            .post(API.faultFinder.getPosts, formData)
            .then((res) => {

                if (res.data.success) {

                    const newPosts = res.data.data.map((post, c) => {

                        const isSubbed = this.state.subscribedIDs.includes(post.id);

                        return <PostCell isSubbed={isSubbed}
                            clickHandler={() => this.postClicked(post)}
                            subHandler={(id,sub) => this.subPostClicked(id,sub)}
                            imageHandler={(imgs) => this.imageClick(imgs)}
                            post={post}
                        />;

                    });
                    this.setState({
                        posts: this.state.posts.concat(newPosts),
                        lastPostId: res.data.lastPostId,
                        infLoading : false
                    })
                }
            })

    }


    getSubscribedIDs = () => {

        Axios
            .get(API.faultFinder.getSubscribedPostIDs)
            .then((res) => {

                if (res.data.success) {

                    this.setState({
                        subscribedIDs: res.data.data

                        })
                }
                this.getPosts();

            })
    }




    handleChange(event) {

        const target = event.target;
        const value = target.value;
        const name = target.name;


        if (name === "applianceType")
        {
            this.setState({applianceBrand:-1, appliance:-1});
            this.getApplianceBrands(value);

            this.getAppliances(-1, -1);
        }
        if (name === "applianceBrand")
        {
            this.setState({appliance:-1});
            this.getAppliances(this.state.applianceType, value);
        }
        this.setState({
            [name]: value
        });


    }



    newPostClicked()
    {
        this.props.showMenuHandler(false);
        const oldScreen = this.state.currentScreen;
        const navStack = this.state.navStack.concat([oldScreen])
        this.setState({
            currentScreen: this.SCREENS.NEW_POST,
            navStack: navStack
        });
    }


    componentDidMount() {

		window.addEventListener("resize", this.refreshGrid);
        this.getSubscribedIDs();
        this.getApplianceTypes();
        this.getApplianceBrands(-1);
        this.getAppliances(-1, -1);

        if (this.state.openPost != null)
        {
            this.openPostOnLoad()
        }
        this.props.showMenuHandler(true);

        if (this.state.subscription === null)
        {
            this.getSubInfo()
        }

    }

    componentWillUnmount() {
    	window.removeEventListener("resize", this.refreshGrid);
    }

    refreshGrid = () => {
    	// console.log('hello');
        if (this.masonry != null) {
            const grid = this.masonry.getBricksInstance();
            grid.pack();
        }
    }


    handleBackClick ()
    {
        const navStack = this.state.navStack.slice();

        if (navStack.length > 1)
        {
            this.props.showMenuHandler(false);
        } else {
            this.props.showMenuHandler(true);

        }
        const backScreen = navStack[navStack.length - 1];

        this.setState({
            currentScreen: backScreen,
            navStack: navStack.slice(0,navStack.length - 1)
        });
    }

    showFiltersClicked()
    {
        this.setState({showingFilters:true});
    }

    hideFiltersClicked()
    {
        this.setState({showingFilters:false});
    }


    getApplianceTypes()
    {
        Axios
            .get(API.appliances.getApplianceTypes)
            .then((res) => {

                if (res.data.success) {

                    var applianceTypes = res.data.data.map((apType, c) => {

                        return <option value={apType.id}>{apType.name}</option>;

                    })

                    applianceTypes.unshift(<option value="-1">Select Appliance Type</option>)
                    this.setState({
                        applianceTypes: applianceTypes

                    })
                }
            })
    }

    getApplianceBrands(applianceType)
    {
        if (applianceType === -1) {
            var applianceBrands = [];
            applianceBrands.unshift(<option value="-1">Select Appliance Make</option>);
            this.setState({
                applianceBrands: applianceBrands

            });
        } else {
            const formData = new FormData();
            formData.append('typeId', applianceType);

            Axios
                .post(API.appliances.getApplianceBrands, formData)
                .then((res) => {

                    if (res.data.success) {

                        var applianceBrands = res.data.data.map((apBrand, c) => {

                            return <option value={apBrand.id}>{apBrand.name}</option>;

                        })

                        applianceBrands.unshift(<option value="-1">Select Appliance Make</option>)
                        this.setState({
                            applianceBrands: applianceBrands

                        })
                    }
                })
        }


    }

    searchClicked()
    {
        this.reload();
    }


    clearFiltersClicked(){

        this.setState({
            applianceType: -1,
            applianceBrand: -1,
            appliance: -1,
            searchText: ""
        });
        this.getApplianceTypes();
        this.getApplianceBrands(-1);
        this.getAppliances(-1, -1);

        this.reload();
    }


    getAppliances(applianceType, applianceBrand) {
        if (applianceBrand === -1) {
            var appliances = [];
            appliances.unshift(<option value="-1">Select Appliance</option>);
            this.setState({
                appliances: appliances

            });
        } else {
            const formData = new FormData();
            formData.append('typeId', applianceType);
            formData.append('brandId', applianceBrand);

            Axios
                .post(API.appliances.getAppliances, formData)
                .then((res) => {

                    if (res.data.success) {

                        var appliances = res.data.data.map((apl, c) => {

                            return <option value={apl.id}>{apl.name}</option>;

                        })

                        appliances.unshift(<option value="-1">Select Appliance</option>)
                        this.setState({
                            appliances: appliances

                        })
                    }
                })
        }
    }

    render() {

        switch (this.state.currentScreen) {

            case this.SCREENS.HOME :

                let nmp = <span className="nmp">No more posts</span>

                return(
                    <div className="main-wrap">
                        <div className="cell small-12 header">
                            <h1 className="head-title fault_finding">Fault Finding</h1>
                            <div onClick={() => this.newPostClicked()} className="ga-button">Create new post</div>

                        </div>
                        <div className="clearfix"></div>
                        {this.state.showingFilters &&

                        <div className="cell small-12 filters create-box">
                        <div className="cell small-12">
                        	<h2>Filters</h2>
                            <form>

                                <div className="cell small-12 medium-6 large-3">
                                	<label>
                                    <span className="create-label">Type :</span> <select className="create-select" value={this.state.applianceType} name="applianceType" onChange={this.handleChange}>{this.state.applianceTypes}</select>
                                	</label>
                                </div>

                                <div className="cell small-12 medium-6 large-3">
                              		<label>
                                    <span className="create-label">Make :</span> <select className="create-select" value={this.state.applianceBrand} name="applianceBrand" onChange={this.handleChange}>{this.state.applianceBrands}</select>
                                	</label>
                                </div>

                                <div className="cell small-12 medium-6 large-3">
                                	<label>
                                    <span className="create-label">Model :</span> <select className="create-select" value={this.state.appliance} name="appliance" onChange={this.handleChange}>{this.state.appliances}</select>
                                	</label>
                                </div>

                                <div className="cell small-12 medium-6 large-3">
                                	<label>
                                    <span className="create-label">Search Text:</span>
                                    <input className="create-search" type="text" name="searchText" placeholder="Search Text" onChange={this.handleChange} value={this.state.searchText} />
                                	</label>
                                </div>


								<div className="cell small-12">
                                	<div onClick={() => this.searchClicked()} className="ga-button">Search</div>
                                	<div onClick={() => this.clearFiltersClicked()} className="ga-button">Clear Filters</div>
                                </div>

                            </form>
                            </div>
                          </div>}
                        <div className="cell small-12">
                            <MasonryLayout 
                            	id="masonry-layout" 
                            	infiniteScroll={this.getPosts} 
                            	infiniteScrollLoading={this.state.infLoading}
                                infiniteScrollEnd={this.state.infEnd}
                                infiniteScrollEndIndicator={nmp}
                                infiniteScrollSpinner="Loading..."
                                ref={instance => this.masonry = instance}
                                sizes={[ { columns: 1, gutter: 20 }, { mq: '640px', columns: 2, gutter: 20 }, { mq: '1280px', columns: 3, gutter: 20 } ]}
                             >

                                {this.state.posts}

                            </MasonryLayout>

                        </div>
                        {this.state.lightboxOpen && <Lightbox
                            mainSrc={this.state.ligtboxImages[this.state.imageIDX]}
                            nextSrc={this.state.ligtboxImages[(this.state.imageIDX + 1) % this.state.ligtboxImages.length]}
                            prevSrc={this.state.ligtboxImages[(this.state.imageIDX + this.state.ligtboxImages.length - 1) % this.state.ligtboxImages.length]}
                            onCloseRequest={() => this.setState({ lightboxOpen: false })}
                            onMovePrevRequest={() =>
                                this.setState({
                                    imageIDX: (this.state.imageIDX + this.state.ligtboxImages.length - 1) % this.state.ligtboxImages.length
                                })
                                }
                            onMoveNextRequest={() =>
                                this.setState({
                                    imageIDX: (this.state.imageIDX + 1) % this.state.ligtboxImages.length
                                })
                                }
                        />}
                    </div>

                          );
            case this.SCREENS.COMMENTS :
                return(<PostComments post={this.state.postToShow}
                    backHandler={() => this.handleBackClick()}
                    refreshHandler={() => this.reloadCurrentPost()}
                />);
            case this.SCREENS.NEW_POST :
                return(<NewPost backHandler={() => this.handleBackClick()}  reloadHandler={() => this.reload()}  />);


            default :
                return(<div>unknown screen</div>);
        }
    }


}

export default FaultFindingPosts;
