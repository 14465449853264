import React from 'react'
import BackButton from "../common/BackButton"
import MainPost from './MainPost'
import Comment from './Comment'
import Lightbox from 'lightbox-react';
import 'lightbox-react/style.css';


class PostComments extends React.Component {

    constructor(props) {
        super(props);

        this.commentRef = null;

        var commentToScrollTo = 0;
        if (this.props.commentToScrollTo != null)
        {
            commentToScrollTo = this.props.commentToScrollTo;
        }

        this.state = {
            lightboxOpen: false,
            imageIDX:0,
            commentToScrollTo: commentToScrollTo
        }

    }


    imageClick(images)
    {
        this.setState({ligtboxImages: images, lightboxOpen:true})
    }


    componentDidMount() {

        if (this.commentRef != null)
        {
           window.scrollTo(0, this.commentRef.current.offsetTop);
        }
    }

    render() {


        const comments = this.props.post.comments.map((comment) => {

            const l2Comments  = comment.comments ? comment.comments.map((l2Comment) => {

                var ref= null;
                if (this.state.commentToScrollTo == l2Comment.id)
                {
                    this.commentRef = React.createRef();
                    ref = this.commentRef;
                }
                return <div ref={ref}><Comment  indent="2" comment={l2Comment} postId={this.props.post.id}
                    replyID={comment.id}
                    refreshHandler={this.props.refreshHandler}
                    user={this.props.user}
                    imageHandler={(imgs) => this.imageClick(imgs)}
                /></div>

            }) : [];


            var ref= null;
            if (this.state.commentToScrollTo == comment.id)
            {
                this.commentRef = React.createRef();
                ref = this.commentRef
            }
            return <div className="reply-wrap" ref={ref}><Comment indent="1" comment={comment}
                refreshHandler={this.props.refreshHandler}
                imageHandler={(imgs) => this.imageClick(imgs)}
                replyID={comment.id}
                user={this.props.user}
                postId={this.props.post.id} />{l2Comments}</div>

        })



        return(

            <div className="main-wrap comments-wrap">
                <BackButton backHandler={this.props.backHandler} />
                <div class="post-detail">
                    <MainPost post={this.props.post}
                        likeHandler={this.props.likeHandler}
                        user={this.props.user}
                        imageHandler={(imgs) => this.imageClick(imgs)}
                        refreshHandler={this.props.refreshHandler}/>
                </div>
                <div className="replies">
                {comments}
                </div>
                                 {this.state.lightboxOpen && <Lightbox
                                     mainSrc={this.state.ligtboxImages[this.state.imageIDX]}
                                     nextSrc={this.state.ligtboxImages[(this.state.imageIDX + 1) % this.state.ligtboxImages.length]}
                                     prevSrc={this.state.ligtboxImages[(this.state.imageIDX + this.state.ligtboxImages.length - 1) % this.state.ligtboxImages.length]}
                                     onCloseRequest={() => this.setState({ lightboxOpen: false })}
                                     onMovePrevRequest={() =>
                                         this.setState({
                                             imageIDX: (this.state.imageIDX + this.state.ligtboxImages.length - 1) % this.state.ligtboxImages.length
                                         })
                                         }
                                     onMoveNextRequest={() =>
                                         this.setState({
                                             imageIDX: (this.state.imageIDX + 1) % this.state.ligtboxImages.length
                                         })
                                         }
                                 />}
            </div>
        );

    }
}

export default PostComments;