import React from 'react'
import { API } from '../../api/APIClient'
import Axios from 'axios'
import BackButton from "../common/BackButton"
import Lightbox from 'lightbox-react';
import 'lightbox-react/style.css';

class TalentDetail extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            userRating:this.props.talent.userRating,
            submitStars: this.generateSubmitStars(this.props.talent.userRating),
            lightboxOpen: false,
            imageIDX:0,
            submitted: false,
            avgRating: this.props.talent.rating
        }

    }


    imageClick(images)
    {
        if (images.length > 0)
        {
            this.setState({ligtboxImages: images, lightboxOpen:true})

        }
    }


    submitRating()
    {
        var ratingToSend = this.state.userRating;

        if (ratingToSend < 1)
        {
            ratingToSend=1;
        }

        const formData = new FormData();
        formData.append('postId', this.props.talent.id);
        formData.append('rating', ratingToSend);

        Axios
            .post(API.talent.submitRating, formData)
            .then((res) => {


                if (res.data.success) {

                    this.setState({
                        submitted: true,
                        avgRating: res.data.data
                    })
                    alert("Thanks, your rating has been received");
                } else {

                    alert("Error sending rating");

                }
            })


    }

    generateSubmitStars(rating)
    {
        var tmpStars = [];
        for (let c=1; c<=5; c++)
        {
            if (rating >= c)
            {
                tmpStars.push(<div className="full-thumb small-thumb" onClick={() => this.starTapped(c)}></div>);
            } else {
                tmpStars.push(<div className="empty-thumb small-thumb" onClick={() => this.starTapped(c)}></div>);

            }
        }
        return tmpStars
    }

    starTapped(starNum)
    {
        this.setState ({
            userRating:starNum,
            submitStars:this.generateSubmitStars(starNum)

        });
    }

    componentDidMount() {


    }

    render() {

        var stars = [];
        for (var c=1; c<=5; c++)
        {
            if (this.state.avgRating >= c)
            {
                stars.push(<div className="full-thumb small-thumb" ></div>);
            } else {
                stars.push(<div className="empty-thumb small-thumb" ></div>);

            }
        }

        var profileImg = "";
        var lbImgs = [];
        if (this.props.talent.profileImageThumb !== null && this.props.talent.profileImageThumb.length > 0 )
        {
            profileImg = <img src={API.BASE_URL + this.props.talent.profileImageThumb} width="32" alt="Profile Thumb" />;
            lbImgs.push(API.BASE_URL + this.props.talent.profileImage);

        } else {
            profileImg = <div className="placeholder-icon" />;

        }


        return(

            <div className="main-wrap">
                <BackButton backHandler={this.props.backHandler} />
                <div className="clearfix"></div>
                <div class="talent-detail">

                    <div className="cell small-12 no-pad">
                        <div className="talent-img">
                            <img src={this.props.talent.image.image} alt="Talent Show Submission" />
                        </div>
                    </div>

                    <div className="cell small-12 ">
                        <div className="main-wrap">
                            <div className="talent-info">
                                <div className="cell small-7 talent-prof">
                                    <div className="prof" onClick={() => this.imageClick(lbImgs)}
                                    >{profileImg}</div>
                                    <div className="details">
                                        <div className="name">{this.props.talent.givenName + " " + this.props.talent.familyName}</div>
                                        <div className="loc">{this.props.talent.location}</div>
                                    </div>
                                </div>

                                <div className="cell small-12 desc">
                                    <div className="description">{this.props.talent.description}</div>
                                </div>
                                <div className="clearfix"></div>
                            </div>
                            
                        </div>
                    </div>

                    <div className="clearfix"/>
                </div>
                {this.state.lightboxOpen && <Lightbox
                    mainSrc={this.state.ligtboxImages[this.state.imageIDX]}
                    nextSrc={this.state.ligtboxImages[(this.state.imageIDX + 1) % this.state.ligtboxImages.length]}
                    prevSrc={this.state.ligtboxImages[(this.state.imageIDX + this.state.ligtboxImages.length - 1) % this.state.ligtboxImages.length]}
                    onCloseRequest={() => this.setState({ lightboxOpen: false })}
                    onMovePrevRequest={() =>
                        this.setState({
                            imageIDX: (this.state.imageIDX + this.state.ligtboxImages.length - 1) % this.state.ligtboxImages.length
                        })
                    }
                    onMoveNextRequest={() =>
                        this.setState({
                            imageIDX: (this.state.imageIDX + 1) % this.state.ligtboxImages.length
                        })
                    }
                />}
            </div>
        );

    }
}

export default TalentDetail;
