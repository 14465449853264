import React from 'react'
import GAButton from '../common/GAButton'
import Terms from '../terms/Terms'
import { API } from '../../api/APIClient'
import Axios from 'axios'
import ReferralStore from "./ReferralStore"

class ReferralsHome extends React.Component  {
    constructor(props) {
        super(props);


        this.SCREENS = {
            HOME : "home",
            TNCs : "terms",
            SHOP : "ReferralStore"
        };
        this.state = {
            currentScreen: this.SCREENS.HOME,
            navStack: [],
            code: " - - - ",
            points: "-",
            isLoading: true,
            debug: {}
        }
    }

    getPartners = () => {

        Axios
            .get(API.referral.getInfo)
            .then((res) => {
                this.setState({
                    debug: res
                });
                if (res.data.success) {

                    this.setState({
                        code: res.data.data.code,
                        points: res.data.data.points
                    })
                }
            })
    }

    handleListItemClick (newScreen)
    {

        this.props.showMenuHandler(false);
        const oldScreen = this.state.currentScreen;
        const navStack = this.state.navStack.concat([oldScreen])
        this.setState({
            currentScreen: newScreen,
            navStack: navStack
        });
    }


    handleBackClick ()
    {
        const navStack = this.state.navStack.slice();

        if (navStack.length > 1)
        {
            this.props.showMenuHandler(false);
        } else {
            this.props.showMenuHandler(true);

        }
        const backScreen = navStack[navStack.length - 1];

        this.setState({
            currentScreen: backScreen,
            navStack: navStack.slice(0,navStack.length - 1)
        });

    }

    componentDidMount() {

        this.getPartners();
        this.props.showMenuHandler(true);


    }
    render() {

        switch (this.state.currentScreen) {

            case this.SCREENS.HOME :
                return (

                    <div className="main-wrap">
                        <div className="cell small-12 header">
                            <h1 className="head-title referrals">Points Store</h1>
                        </div>
                        <div className="cell small-12">
                            <div className="rounded-main">
                                
                                <div className="main-wrap">
                                    <div className="cell small-12">
                                        <div className="rounded-white ref-sch">
                                            <div className="title-text">Your Code</div>
                                            <div className="body-text">Your referral code to share with friends and colleagues and earn 100 points per sign-up is:</div>
                                            <div className="code">{this.state.code}</div>
                                            <div className="clearfix"></div>
                                            <a href={`mailto:?body=I thought you'd like to check out Gas App Uk, its a great app for gas engineers with manuals, forms, break time and much more. Use this link to give it a try https://gasappukportal.app-cms.com/download/index/` + this.state.code}>
                                                <GAButton imageClass="menu-icon menu-icon-referral" title="Share Your Code" />
                                            </a>
                                        </div>
                                    </div>
                                    <div className="cell small-12">
                                        <div className="rounded-white ref-sch">
                                            <div className="title-text">Your Points</div>
                                            <div className="body-text">Your current points balance earned by referring people to Gas App Uk is:</div>
                                            <div className="points"><span>{this.state.points}</span> pts</div>
                                            <div className="body-text add-space">You can redeem your points against items in our store.</div>
                                            <GAButton clickHandler={() => this.handleListItemClick(this.SCREENS.SHOP)} screenName={this.SCREENS.SHOP} imageClass="menu-icon menu-icon-competition" title="View Store" />
                                            <div className="tnc-text underline" onClick={() => this.handleListItemClick(this.SCREENS.TNCs)}>For full Terms and Conditions tap here</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="clearfix"></div>
                            </div>
                        </div>
                    </div>
                );
            case this.SCREENS.TNCs :
                return (<Terms backHandler={() => this.handleBackClick()}  />);
            case this.SCREENS.SHOP :
                return (<ReferralStore backHandler={() => this.handleBackClick()} userID={this.props.user.id}  />);

            default :
                return (<div>unknown screen</div>);
        }
    }

}

export default ReferralsHome;
