import React from 'react'
import Subscription from '../settings/Subscription'
import Amex from '../../assets/img/amex.svg'
import Mastercard from '../../assets/img/mastercard.svg'
import Visa from '../../assets/img/visa.svg'
import Stripe from '../../assets/img/powered_by_stripe.svg'
import Tick from '../../assets/img/tick.svg'
import Devices from '../../assets/img/apple-devices2.jpg'


class PleaseSubscribe extends React.Component {

    constructor(props) {
        super(props);


        this.SCREENS = {
            HOME: "sub",
            SUBSCRIPTION: "subDetails"
        };

        this.state = {
            navStack: [],
            currentScreen: this.SCREENS.HOME
        };
    }





    handleBackClick ()
    {
        const navStack = this.state.navStack.slice();

        const backScreen = navStack[navStack.length - 1];

        this.setState({
            currentScreen: backScreen,
            navStack: navStack.slice(0,navStack.length - 1)
        });
    }

    subscribeClicked (term)
    {
        const oldScreen = this.state.currentScreen;
        const navStack = this.state.navStack.concat([oldScreen])
        this.setState({
            paymentSelected:term,
            currentScreen: this.SCREENS.SUBSCRIPTION,
            navStack: navStack
        });
    }
    render() {

        switch (this.state.currentScreen) {

            case this.SCREENS.SUBSCRIPTION:
                return (<div><Subscription
                    backHandler={() => this.handleBackClick()}
                    user={this.props.user}
                    term={this.state.paymentSelected}
                    userHandler={this.props.userHandler}
                /></div>);

            case this.SCREENS.HOME :

                return (
                        <div className="loginMain">
                            <div className="top-space">
                                <div className="navbar">
                                    <div className="logout" onClick={this.props.logoutHandler}>Log out</div>
                                    <div className="logo"></div>
                                </div>
                            </div>
                        <div className="main-wrap">
                            <div className="sub-box">
                                <h1 className="head-title ">Please Subscribe </h1>

                                <div className="main-wrap pricing">



                                    <div className="main-wrap what-you-get">
                                        <div className="cell small-12">
                                          <h2>What you get</h2>
                                          <p>All Gas App Uk subscriptions come with all of these amazing features.</p>
                                          <div className="main-wrap features">

                                            

                                            <div className="feature cell small-12 medium-6 large-4">
                                              <div className="tick-wrap">
                                                <img src={Tick} alt="" />
                                                <div className="tick-text">
                                                  <span className="tick-title">Bespoke Quoting Software</span>
                                                  <span className="tick-note">A quoting tool that takes the strain out of managing your business by enabling you to email quotes containing your business details and logo to customers in less than a few minutes.</span>
                                                </div>
                                              </div>
                                            </div>

                                            <div className="feature cell small-12 medium-6 large-4">
                                              <div className="tick-wrap">
                                                <img src={Tick} alt="" />
                                                <div className="tick-text">
                                                  <span className="tick-title">Digital Forms</span>
                                                  <span className="tick-note">Domestic job sheets, gas inspection records, landlord gas safety records, legionnaire risk assessments, warning notices and much more. Easily add your company details and logo and quickly email the relevant forms to your customers or print them off.</span>
                                                </div>
                                              </div>
                                            </div>

                                            <div className="feature cell small-12 medium-6 large-4">
                                              <div className="tick-wrap">
                                                <img src={Tick} alt="" />
                                                <div className="tick-text">
                                                  <span className="tick-title">PDF Reference Library</span>
                                                  <span className="tick-note">A library full of important PDF documents to refer back to when you need to. The library includes servicing checklists, meter docs, unsafe procedures, gas rating, ACS refresher and much more.</span>
                                                </div>
                                              </div>
                                            </div>

                                            <div className="feature cell small-12 medium-6 large-4">
                                              <div className="tick-wrap">
                                                <img src={Tick} alt="" />
                                                <div className="tick-text">
                                                  <span className="tick-title">Thousands of Manuals and MI’s</span>
                                                  <span className="tick-note">Manuals for both domestic and commercial appliances, including current, backdated and even obsolete manuals.</span>
                                                </div>
                                              </div>
                                            </div>

                                            

                                            <div className="feature cell small-12 medium-6 large-4">
                                              <div className="tick-wrap">
                                                <img src={Tick} alt="" />
                                                <div className="tick-text">
                                                  <span className="tick-title">Toolbox</span>
                                                  <span className="tick-note">Pipe sizing calculator, gas rating, ventilation and purge calculators, water PPM calculator, direct manufacturer quick dial contact numbers and video tutorials.</span>
                                                </div>
                                              </div>
                                            </div>

                                            <div className="feature cell small-12 medium-6 large-4">
                                              <div className="tick-wrap">
                                                <img src={Tick} alt="" />
                                                <div className="tick-text">
                                                  <span className="tick-title">Live Fault Finding</span>
                                                  <span className="tick-note">A community of engineers working together to help each other diagnose boiler repairs safely and quickly on the job.</span>
                                                </div>
                                              </div>
                                            </div>

                                            

                                            <div className="feature cell small-12 medium-6 large-4">
                                              <div className="tick-wrap">
                                                <img src={Tick} alt="" />
                                                <div className="tick-text">
                                                  <span className="tick-title">Breaktime</span>
                                                  <span className="tick-note">Interact and share stories from your work with other members of our community.</span>
                                                </div>
                                              </div>
                                            </div>

                                            <div className="feature cell small-12 medium-6 large-4">
                                              <div className="tick-wrap">
                                                <img src={Tick} alt="" />
                                                <div className="tick-text">
                                                  <span className="tick-title">News</span>
                                                  <span className="tick-note">The latest newsroom content in real-time from key publications including PHAM News, Installer and PHPI.</span>
                                                </div>
                                              </div>
                                            </div>

                                            

                                            <div className="feature cell small-12 medium-6 large-4">
                                              <div className="tick-wrap">
                                                <img src={Tick} alt="" />
                                                <div className="tick-text">
                                                  <span className="tick-title">Horror Show Galleries</span>
                                                  <span className="tick-note">Monthly giveaway for the best (worst) picture of the most shocking work done by rogue traders that you’ve come across on the job.</span>
                                                </div>
                                              </div>
                                            </div>

                                            <div className="feature cell small-12 medium-6 large-4">
                                              <div className="tick-wrap">
                                                <img src={Tick} alt="" />
                                                <div className="tick-text">
                                                  <span className="tick-title">Competitions</span>
                                                  <span className="tick-note">Running continuously with fantastic prizes.</span>
                                                </div>
                                              </div>
                                            </div>

                                          </div>
                                        </div>
                                    </div>

                                    <div className="main-wrap what-its-on">
                                        <div className="cell small-12">
                                          <h2>Available on all platforms</h2>
                                          <img src={Devices} alt="" />
                                        </div>
                                    </div>

                                    

                                    <div className="clearfix"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                );

            default :
            return (<div>unknown screen</div>);
        }
    }
}

export default PleaseSubscribe;
