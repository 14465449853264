import React from 'react'
import Menu from './Menu'
import {SECTIONS} from '../../resources/strings'
import { Link } from "react-router-dom";
import moment from 'moment';


function NavBar(props) {

    const mainDivClass = props.showMenu ? "top-space" : "top-space smaller-space";

    var countdown = <div></div>
    if (props.subscription != null && props.subscription.userType == 2)
    {
        var m = moment(props.subscription.expires, 'YYYY-MM-DD HH:mm:ss');
        countdown = <div className="countdown">{"Your free trial expires " + m.fromNow()}</div>;
    }





    return(
    	<div className={mainDivClass}>
                <div className="navbar">
            {countdown}

                <Link to={`/` + SECTIONS.SETTINGS}><div className="settings"></div></Link>

                <div className="logo"></div>
	            {props.showMenu && <Menu menuClickHandler={props.menuClickHandler}/>}
	        </div>
        </div>
    );
}

export default NavBar;
