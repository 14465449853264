import React from 'react'

function VideoCell(props) {

    return(
        <div className="post-cell break-video">
            <div className="video-cell" onClick={props.clickHandler}>
                <div class="video-image">
                    <img src={props.image} alt="Video Thumb" />
                    <div className="play-btn"></div>
                </div>
                <div className="video-overlay">
                    <div>New Video</div>
                    <div className="vid-title">{props.title}</div>
                </div>
            </div>
        </div>
    );

}

export default VideoCell;
