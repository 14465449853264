import React from 'react'

function GAButton(props) {

    if (props.clickHandler) {
        return(

            <div onClick={props.clickHandler} className="ga-button">

            { props.imageClass && <div className={props.imageClass} ></div> }
                <div className="btn-text">{props.title}</div>
            </div>
        );
    } else {
        return(
            <div className="ga-button">

            { props.imageClass && <div className={props.imageClass} ></div> }
                <div className="btn-text">{props.title}</div>
            </div>
        );
    }

}

export default GAButton;
