import React from 'react';

import "../../assets/css/loading-spinner.css";
import flame from "../../assets/img/loading_flame.png";

class LoadingSpinner extends React.Component {

    render() {
        return(
            <div className={"ga-loading-spinner"} style={{"backgroundImage" : "url(" + flame + ")"}}>
                <div className={"spinny"} />
            </div>
        );
    }

}

export default LoadingSpinner;