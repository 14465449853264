import React from 'react'

function MenuItem(props) {

    return(
        <div className="menuItem" onClick={props.onClick}>
            <div className={"menu-icon " + props.iconClass} />
            <span>{props.itemName}</span>
        </div>
    );
}


export default MenuItem;
