import React from 'react'

function ContactItem(props) {

    return(
        <div className="list-item">
            <div className="title-text">{props.title}</div>
            <div className="phone">Tel: <span className="phone-number">{props.telephone}</span></div>
        </div>
    );


}

export default ContactItem;
