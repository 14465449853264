import React from 'react'
import { Link } from "react-router-dom";
import {SECTIONS} from '../../resources/strings'

function KnowledgeCell(props) {
    const location = {
        pathname: `/` + SECTIONS.FAULT_FINDING,
        state: { openPost: props.post.id  }
    };
    return(

        <div className="post-cell break-fault">
            <Link to={location} style={{ textDecoration: 'none', color: '#000000' }}>
        	<div className="fault-wrap">
	            <div className="fault-icon"></div>
	            <div className="fault-details">
	            	<div>{props.post.givenName} {props.post.familyName} <span className="sub-text">would like some help with</span></div>
	            	<div className="title">{props.post.title}</div>
	            </div>
	            <div className="pdf-link">{props.post.applianceType} > {props.post.applianceBrand} > {props.post.applianceName}</div>
            </div>
            </Link>
        </div>

    );


}

export default KnowledgeCell;
