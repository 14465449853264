import React from 'react'

function PDFItem(props) {

    return(
        <div onClick={()=> window.open(props.pdfUrl, "_blank")} className="list-item">
            {props.title}
        </div>
    );


}

export default PDFItem;
