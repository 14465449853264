import React from 'react'
import BackButton from "../common/BackButton"
import MainPost from './MainPost'
import Comment from './Comment'
import Lightbox from 'lightbox-react';
import 'lightbox-react/style.css';

class PostComments extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            lightboxOpen: false,
            imageIDX:0
        }
    }

    imageClick(images)
    {
        this.setState({ligtboxImages: images, lightboxOpen:true})
    }

    render() {

        var markedFirst  = false;
        const comments = this.props.post.comments.map((comment) => {

            const l2Comments  = comment.comments ? comment.comments.map((l2Comment) => {

                return <Comment indent="2" resolution={this.props.post.resolution}
                    comment={l2Comment} postId={this.props.post.id}
                    imageHandler={(imgs) => this.imageClick(imgs)}
                    own_post={this.props.post.own_post}
                    replyID={comment.id}
                    isFirst={false}
                    refreshHandler={this.props.refreshHandler} />

            }) : [];

            var isFirst = false;
            if (!markedFirst)
            {
                isFirst = true;
                markedFirst = true;
            }
            return <div className="reply-wrap"><Comment indent="1" resolution={this.props.post.resolution}
                refreshHandler={this.props.refreshHandler}
                own_post={this.props.post.own_post}
                isFirst={isFirst}
                replyID={comment.id}
                imageHandler={(imgs) => this.imageClick(imgs)}
                comment={comment} postId={this.props.post.id} />{l2Comments}</div>

        })



        return(

            <div className="main-wrap comments-wrap">
                <BackButton backHandler={this.props.backHandler} />
                <div class="post-detail">
                    <MainPost post={this.props.post}
                        imageHandler={(imgs) => this.imageClick(imgs)}
                        refreshHandler={this.props.refreshHandler} />
                </div>
                <div className="replies">
                {comments}
                </div>
                 {this.state.lightboxOpen && <Lightbox
                     mainSrc={this.state.ligtboxImages[this.state.imageIDX]}
                     nextSrc={this.state.ligtboxImages[(this.state.imageIDX + 1) % this.state.ligtboxImages.length]}
                     prevSrc={this.state.ligtboxImages[(this.state.imageIDX + this.state.ligtboxImages.length - 1) % this.state.ligtboxImages.length]}
                     onCloseRequest={() => this.setState({ lightboxOpen: false })}
                     onMovePrevRequest={() =>
                         this.setState({
                             imageIDX: (this.state.imageIDX + this.state.ligtboxImages.length - 1) % this.state.ligtboxImages.length
                         })
                         }
                     onMoveNextRequest={() =>
                         this.setState({
                             imageIDX: (this.state.imageIDX + 1) % this.state.ligtboxImages.length
                         })
                         }
                 />}
            </div>
        );

    }
}

export default PostComments;