import React from 'react'
import GasApp from "./components/GasApp"
import './App.css'
import './assets/css/main.css';

function App() {
  return (

          <div className="App">
              <GasApp />
          </div>
  );
}




export default App;
