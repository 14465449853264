import React from 'react'
import TalentCell from './TalentCell'
import { API } from '../../api/APIClient'
import Axios from 'axios'
import TalentDetail from './TalentDetail'
import TalentNewPost from './TalentNewPost'
import InfiniteScroll from 'react-infinite-scroll-component'

class TalentPosts extends React.Component {

    constructor(props) {
        super(props);

        this.SCREENS = {
            HOME: "home",
            TALENT_DETAIL: "talent",
            TALENT_NEW_POST: "newpost"
        };

        this.state = {
            currentScreen: this.SCREENS.HOME,
            navStack: [],
            talents: [],
            moreTalent: true,
            lastPostID: 0
        };
    }


    talentCellClicked(talent)
    {
        this.props.showMenuHandler(false);
        const oldScreen = this.state.currentScreen;
        const navStack = this.state.navStack.concat([oldScreen])
        this.setState({
            currentScreen: this.SCREENS.TALENT_DETAIL,
            navStack: navStack,
            talentToShow: talent
        });
    }

    newPostClicked()
    {
        this.props.showMenuHandler(false);
        const oldScreen = this.state.currentScreen;
        const navStack = this.state.navStack.concat([oldScreen])
        this.setState({
            currentScreen: this.SCREENS.TALENT_NEW_POST,
            navStack: navStack
        });
    }


    handleBackClick ()
    {
        const navStack = this.state.navStack.slice();

        if (navStack.length > 1)
        {
            this.props.showMenuHandler(false);
        } else {
            this.props.showMenuHandler(true);

        }
        const backScreen = navStack[navStack.length - 1];

        this.setState({
            currentScreen: backScreen,
            navStack: navStack.slice(0,navStack.length - 1)
        });
    }

    getTalents = () => {
        const formData = new FormData();
        if (this.state.lastPostID > 0)
        {
            formData.append('afterId', this.state.lastPostID);
        }
        formData.append('count', 10);

        Axios
            .post(API.talent.getTalents, formData)
            .then((res) => {

                if (res.data.success) {

                    var lastPostID = 0;
                    const newTalents = res.data.data.map((talent, c) => {
                        lastPostID = talent.id
                        return <TalentCell clickHandler={() => this.talentCellClicked(talent)} location={talent.location}
                                           rating={talent.rating}
                                           image={talent.image.thumb}
                                           user={talent.givenName + " " + talent.familyName}
                        />;

                    });

                    if (newTalents.length > 0)
                    {
                        this.setState({
                            talents:this.state.talents.concat(newTalents),
                            lastPostID: lastPostID
                        });

                    } else {
                        this.setState({
                            moreTalent: false
                        });
                    }
                }
            })
    }


    componentDidMount() {

        this.getTalents();
        this.props.showMenuHandler(true);

    }


    render() {

        switch (this.state.currentScreen) {

            case this.SCREENS.HOME :
                return (
                    <div className="main-wrap">
                        <div className="cell small-12 header talentposts-header">
                            <h1 className="head-title talent_show">Talent Show</h1>
                            <div onClick={() => this.newPostClicked()} className="ga-button">Create new post</div>

                        </div>
                        <div className="cell small-12">
                            <InfiniteScroll
                                dataLength={this.state.talents.length}
                                next={this.getTalents}
                                hasMore={this.state.moreTalent}
                                loader={<h4>Loading...</h4>}
                                endMessage={
                                    <div>
                                        <div className="clearfix"></div>
                                        <p className="end-mes">
                                            That's all we have!
                                        </p>
                                    </div>

                                }>
                                {this.state.talents}
                            </InfiniteScroll>

                        </div>
                    </div>
                );

            case this.SCREENS.TALENT_DETAIL :
                return(<TalentDetail talent={this.state.talentToShow} backHandler={() => this.handleBackClick()}  />);

            case this.SCREENS.TALENT_NEW_POST :
                return(<TalentNewPost backHandler={() => this.handleBackClick()}  />);

            default :
                return(<div>unknown screen</div>);
        }
    }
}

export default TalentPosts;
