import React from 'react'
import SectionButton from '../common/SectionButton'



class OfficeHome extends React.Component {

    constructor(props) {
        super(props);

        this.SCREENS = {
            HOME : "home",
            CUSTOMERS : "customers",
            QUOTES : "quotes",
            REMINDERS: "reminders"
        };

        this.state = {
            currentScreen: this.SCREENS.HOME,
            navStack: []
        };
    }

    handleListItemClick (newScreen) {
        if (newScreen === this.SCREENS.CUSTOMERS) {

            window.location.href = "https://gasapp.app-cms.com/portal/customers/index/" + this.props.user.token + "/" + this.props.user.id;
        } else if (newScreen === this.SCREENS.REMINDERS) {

            window.location.href = "https://gasapp.app-cms.com/portal/reminders/index/" + this.props.user.token + "/" + this.props.user.id;
        } else if (newScreen === this.SCREENS.QUOTES) {

            window.location.href = "https://gasapp.app-cms.com/portal/quotes/index/" + this.props.user.token + "/" + this.props.user.id;
        }
    }

    componentDidMount()
    {
        this.props.showMenuHandler(true);

    }


    render() {
        return (
            <div className="main-wrap">
                <div className="cell small-12 header">
                    <h1 className="head-title office">Office</h1>
                </div>
                <div className="cell small-12">
                    <SectionButton title="Customers and Forms" sectionClick={() => this.handleListItemClick(this.SCREENS.CUSTOMERS)} iconClass="icon-customers"/>
                    <SectionButton title="Quotes" image="" sectionClick={() => this.handleListItemClick(this.SCREENS.QUOTES)} iconClass="icon-quotes"/>
                    <SectionButton title="Form Reminders" image="" sectionClick={() => this.handleListItemClick(this.SCREENS.REMINDERS)} iconClass="icon-bell"/>
                </div>
            </div>
        );
    }
}

export default OfficeHome;
