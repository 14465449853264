import React from 'react'
import { API } from '../../api/APIClient'
import Axios from 'axios'
import BackButton from "../common/BackButton"


class TalentNewPost extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            submitting: false,
            submitMessage: ""
        }
        this.handleChange = this.handleChange.bind(this);
        this.fileChangedHandler = this.fileChangedHandler.bind(this);
    }



    handleChange(event) {

        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    fileChangedHandler = (event) => {
        const file = event.target.files[0]

        if (file.name.length > 4)
        {
            var last3 = file.name.substr(file.name.length - 3).toUpperCase();
            var last4 = file.name.substr(file.name.length - 4).toUpperCase();

            if (last4 === "JPEG" || last3 === "JPG" || last3 === "PNG")
            {
                this.setState({
                    image: file
                });
            } else {
                alert('Please choose an image file (jpg or png)');
            }

        } else {
            alert('Please choose an image file (jpg or png)');
        }

    }

    submitClicked() {

        if (this.state.location !== undefined && this.state.description !== undefined &&
            this.state.location.length > 0  && this.state.description.length > 0)
        {
            if (this.state.image !== undefined)
            {
                this.submitPost();

            } else {
                alert('Please choose an image file (jpg or png)');
            }

        } else {

            alert('Please enter a location and description');
        }

    }

    submitPhoto(){

        this.setState({
            submitting:true,
            submitMessage:"Uploading image..."
        });

        const formData = new FormData();
        formData.append('postId', this.state.postID);
        formData.append('image', this.state.image, this.state.image.name);

        Axios
            .post(API.talent.uploadImage, formData)
            .then((res) => {

                this.setState({
                    submitting:false,
                    submitMessage:""
                });
                if (res.data.success) {

                    alert("We have received your post but note that there will be a short delay before it appears while our moderators approve it.");

                } else {

                    alert("Error sending image.  Please check your connection and try again");

                }
            })

    }

    submitPost()
    {

        this.setState({
            submitting:true,
            submitMessage:"Sending post..."
        });
        const formData = new FormData();
        formData.append('description', this.state.description);
        formData.append('location', this.state.location);

        Axios
            .post(API.talent.submitPost, formData)
            .then((res) => {


                if (res.data.success) {

                    this.setState({
                        postID: res.data.data
                    });
                    this.submitPhoto();
                } else {

                    alert("Error sending post.  Please check your connection and try again");

                }
            })


    }


    componentDidMount() {


    }

    render() {


        return(

            <div className="main-wrap">
                <BackButton backHandler={this.props.backHandler} />

                <div className="create-box">
                    <div className="cell small-12">
                        <h2>Create New Talent Show Post</h2>
                        <form>

                            <div className="cell small-12 medium-6">
                                <label>
                                    <span className="create-label">Town / City:</span>
                                    <input className="create-title" type="text" name="location" placeholder="Town / City" onChange={this.handleChange}/>
                                </label>
                            </div>

                            <div className="cell small-12 medium-6">
                                <label>
                                    <span className="create-label">Description:</span>
                                    <input className="create-title" type="text" name="description" placeholder="Description" onChange={this.handleChange}/>
                                </label>
                            </div>

                            <div className="cell small-12">
                                <label>
                                    <span className="create-label">Add Image :</span>
                                    <input type="file" name="image" onChange={this.fileChangedHandler} accept=".jpg,.jpeg,.png"/>
                                </label>
                            </div>

                            {this.state.submitting ? <div>{this.state.submitMessage}</div> : <div onClick={() => this.submitClicked()} className="ga-button">Submit</div>}

                        </form>
                    </div>
                </div>
            </div>
        );

    }
}

export default TalentNewPost;
