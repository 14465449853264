import React from 'react'
import { API } from '../../api/APIClient'
import Axios from 'axios'


class CardVerify extends React.Component {

    constructor(props) {
        super(props);


        this.SCREENS = {
            HOME: "verify"};

        this.state = {
            currentScreen: this.SCREENS.HOME,
            code: "",
            submitting:false,
            navStack: [],
            resending: false
        };

    }




    handleBackClick ()
    {
        const navStack = this.state.navStack.slice();

        const backScreen = navStack[navStack.length - 1];

        this.setState({
            currentScreen: backScreen,
            navStack: navStack.slice(0,navStack.length - 1)
        });
    }



    render() {

        switch (this.state.currentScreen) {

            case this.SCREENS.HOME :

                return (
                    <div className="loginMain">
                        <div className="main-wrap">
                            <div  style={{width: "100%",padding:"12px",textAlign:"right"}}>
                                <span onClick={this.props.logoutHandler}  style={{cursor: "pointer",color:"white"}}>Log Out</span>
                            </div>
                            <div className="reg-box">
                                <h1 className="head-title ">ID Card Verification</h1>

                                <div className="main-wrap">

                                    <div className="cell small-12 ">
                                        Thanks for creating your account, this will be live just as soon as we verify your Gas Safe details. An email will be sent to you when your account is live usually within 24 hours.
                                    </div>
                                    <div className="cell small-12 ">
                                        Existing users - please log out and back in and your ID card will be recognised.
                                    </div>

                                    <div className="clearfix"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            default :
                return <div>Unknown screen</div>
        }
    }
}

export default CardVerify;
