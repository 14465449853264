import React from 'react'
import NewsCell from './NewsCell'
import { API } from '../../api/APIClient'
import Axios from 'axios'
import InfiniteScroll from 'react-infinite-scroll-component'

class Newsroom extends React.Component {

    constructor(props) {
        super(props);

        this.SCREENS = {
            HOME : "home",
            ARTICLE : "article"
        };

        this.state = {
            currentScreen: this.SCREENS.HOME,
            navStack: [],
            articles: [],
            moreArticles: true,
            page: 0
        };
    }

    handleArticleClick(url) {

        window.open(url, "_blank")
    }

    getArticles = () => {

        const formData = new FormData();
        if (this.state.page > 0)
        {
            formData.append('page', this.state.page);
        }

        Axios
            .post(API.newsroom.getArticles, formData)
            .then((res) => {

                if (res.data.success) {

                    const newArticles = res.data.data.map((article, c) => {

                        return <NewsCell articleClickHandler={(url) => this.handleArticleClick(article.linkUrl)} title={article.title}
                            date={article.publishDate}
                            description={article.description}
                        />;
                    });


                    if (newArticles.length > 0)
                    {
                        this.setState({
                            articles:this.state.articles.concat(newArticles),
                            page: this.state.page + 1
                        });

                    } else {
                        this.setState({
                            moreArticles: false
                        });
                    }
                }
            })
    }


    componentDidMount() {

        this.getArticles();
        this.props.showMenuHandler(true);

    }

    render() {

        return (
            <div className="main-wrap">
                <div className="cell small-12 header">
                    <h1 className="head-title newsroom">Newsroom</h1>
                </div>
                <div className="cell small-12">
                    <InfiniteScroll
                        dataLength={this.state.articles.length}
                        next={this.getArticles}
                        hasMore={this.state.moreArticles}
                        loader={<h4>Loading...</h4>}
                        endMessage={
                            <p style={{textAlign: 'center'}}>
                                <b>That's all the articles we have!</b>
                            </p>

                    }>

                    {this.state.articles}

                    </InfiniteScroll>
                </div>
            </div>
        );
    }
}

export default Newsroom;
