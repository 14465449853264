import React from 'react'

function SectionButton(props) {

    return(
        <div className="list-item list-arrow" onClick={() => props.sectionClick()}>
            {props.iconClass && <div className={"sectionIcon " + props.iconClass}  />}
                        {props.iconImage && <div style={{display: "inline-block", width: "90px", verticalAlign: "middle"}}><img height="90" src={props.iconImage} alt="Section Icon" /></div>}

            <div className="section-title">{props.title}</div>
            
        </div>
    );
}

export default SectionButton;
