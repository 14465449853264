import React from 'react'
import { API } from '../../api/APIClient'
import Axios from 'axios'
import BackButton from "../common/BackButton"


class NewPost extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            submitting: false,
            submitMessage: ""

        }
        this.handleChange = this.handleChange.bind(this);
        this.fileChangedHandler = this.fileChangedHandler.bind(this);
    }



    handleChange(event) {

        const target = event.target;
        const value = target.value;
        const name = target.name;


        if (name === "applianceType")
        {
            this.getApplianceBrands(value);

            this.getAppliances(-1, -1);
        }
        if (name === "applianceBrand")
        {
            this.getAppliances(this.state.applianceType, value);
        }
        this.setState({
            [name]: value
        });


    }


    fileChangedHandler = (event) => {
        const file = event.target.files[0]

        if (file.name.length > 4)
        {
            var last3 = file.name.substr(file.name.length - 3).toUpperCase();
            var last4 = file.name.substr(file.name.length - 4).toUpperCase();

            if (last4 === "JPEG" || last3 === "JPG" || last3 === "PNG")
            {
                const name = event.target.name;

                this.setState({
                    [name]: file
                });
            } else {
                alert('Please choose an image file (jpg or png)');
            }

        } else {
            alert('Please choose an image file (jpg or png)');
        }
    }

    submitClicked() {

        if ( this.state.description !== undefined && this.state.description.length > 0)
        {

            this.submitPost();

        } else {

            alert('Please enter some text.');
        }

    }


    submitPost()
    {

        this.setState({
            submitting:true,
            submitMessage:"Sending post..."
        });
        const formData = new FormData();
        formData.append('body', this.state.description);

        Axios
            .post(API.breakTime.submitPost, formData)
            .then((res) => {

                if (res.data.success) {

                    var imagesToUpload = [];
                    if (this.state.image1) {imagesToUpload.push(this.state.image1);}
                    if (this.state.image2) {imagesToUpload.push(this.state.image2);}
                    if (this.state.image3) {imagesToUpload.push(this.state.image3);}
                    if (this.state.image4) {imagesToUpload.push(this.state.image4);}

                    if (imagesToUpload.length > 0)
                    {
                        this.setState(
                            {imagesToUpload: imagesToUpload,
                                postID: res.data.data
                            }
                        );
                        this.uploadNextimage();

                    } else {
                        this.doneReplying();
                    }

                } else {

                    alert("Could not send reply.  Please check connection and try again.")
                }

            })


    }


    uploadNextimage()
    {
        this.setState({
            submitting:true,
            submitMessage:"Uploading images, " + this.state.imagesToUpload.length  + " remaining..."
        });

        if (this.state.imagesToUpload.length > 0)
        {
            const formData = new FormData();
            formData.append('postId', this.state.postID);
            formData.append('image', this.state.imagesToUpload[0], this.state.imagesToUpload[0].name);

            Axios
                .post(API.breakTime.uploadImage, formData)
                .then((res) => {

                    const newimgArr = this.state.imagesToUpload.slice(1);
                    this.setState({
                        imagesToUpload:newimgArr
                    });
                    this.uploadNextimage();

                })
        } else {
            this.doneReplying();
        }



    }


    doneReplying()
    {
        this.setState({
            isReplying:false,
            submitting:false,
            updatedText: this.state.editText,
            submitMessage:""
        });
        this.props.backHandler();
        this.props.reloadHandler();

    }


    componentDidMount() {



    }

    render() {


        return(

            <div className="main-wrap">
                <BackButton backHandler={this.props.backHandler} />

                <div className="create-box">
                    <div className="cell small-12">
                        <h2>Create New Break Time Post</h2>
                        <form>


                            <div className="cell small-12">
                                <label>
                                    <span className="create-label">Enter Text:</span>
                                    <textarea className="create-comment" type="text" name="description" placeholder="Please start your conversation here…." onChange={this.handleChange}/>
                                </label>
                            </div>

                            <div className="cell small-12">
                                <label>
                                    <span className="create-label">Add Images:</span>
                                    <div className="upload-wrap">
                                        <div className="cell small-12 medium-6 large-3">
                                            <input type="file" name="image1" onChange={this.fileChangedHandler} accept=".jpg,.jpeg,.png"/>
                                        </div>
                                        <div className="cell small-12 medium-6 large-3">
                                            <input type="file" name="image2" onChange={this.fileChangedHandler} accept=".jpg,.jpeg,.png"/>
                                        </div>
                                        <div className="cell small-12 medium-6 large-3">
                                            <input type="file" name="image3" onChange={this.fileChangedHandler} accept=".jpg,.jpeg,.png"/>
                                        </div>
                                        <div className="cell small-12 medium-6 large-3">
                                            <input type="file" name="image4" onChange={this.fileChangedHandler} accept=".jpg,.jpeg,.png"/>
                                        </div>
                                    </div>
                                </label>
                            </div>

                        {this.state.submitting ? <div>{this.state.submitMessage}</div> : <div onClick={() => this.submitClicked()} className="ga-button">Submit</div>}

                        </form>
                    </div>
                </div>
            </div>
        );

    }
}

export default NewPost;
