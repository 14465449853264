export const SECTIONS = {
    PARTNERS : "partners",
    REFERRALS : "pointsstore",
    TOOLBOX : "toolbox",
    OFFICE : "office",
    COMPETITION : "competition",
    BREAK_TIME : "breaktime",
    FAULT_FINDING : "faultfinding",
    NEWSROOM : "newsroom",
    MASSIVE_DEALS : "massivedeals",
    HORROR_SHOW : "horrorshow",
    TALENT_SHOW : "talentshow",
    SETTINGS : "account",
    WEBSITES : "website"
};
