import React from 'react';
import LoadingSpinner from "../common/LoadingSpinner";
import Axios from "axios";
import {API, APIHelper} from "../../api/APIClient";

import '../../assets/css/websites.css';
import WebsitesAdvertisement from "./WebsitesAdvertisement";
import {SECTIONS} from "../../resources/strings";
import {WebsitesUtil} from "../../resources/WebsitesUtil";

const VIEW_MODE_UNSUBSCRIBED = 1;
const VIEW_MODE_SUBSCRIBED = 2;

class WebsiteLanding extends React.Component {

    constructor(props, context) {
        super(props, context);

        this.state = {
            viewMode : VIEW_MODE_UNSUBSCRIBED,
            website : null,
            showBlocker : true,
            websitesNetworkInFlight : false,
            linkCopied : false
        };

        this.getWebsiteStatusFromNetwork = this.getWebsiteStatusFromNetwork.bind(this);
        this.onSubscription = this.onSubscription.bind(this);
        this.copyLink = this.copyLink.bind(this);
    }

    componentDidMount() {
        this.getWebsiteStatusFromNetwork();
    }

    getWebsiteStatusFromNetwork() {
        Axios.get(API.websites.getStatus)
            .then((r) => {
                let newStatus = VIEW_MODE_UNSUBSCRIBED;

                let resp = APIHelper.parse(r);
                if (resp.success) {
                    if (resp.data.active) {
                        newStatus = VIEW_MODE_SUBSCRIBED;

                        this.getWebsitesFromNetwork();
                    }
                } else {
                    console.log(resp.error);
                }

                this.setState({
                    viewMode : newStatus,
                    showBlocker : false
                });
            })
            .catch((e) => {
                console.log(e);
            });
    }

    getWebsitesFromNetwork() {
        this.setState({
            websitesNetworkInFlight : true
        });

        Axios.get(API.websites.getWebsites)
            .then((r) => {
                let website = null;

                let resp = APIHelper.parse(r);
                if (resp.success) {
                    if (resp.data.websites.length > 0) {
                        website = resp.data.websites[0];
                    }
                } else {
                    console.log(resp.error);
                }

                this.setState({
                    website : website,
                    websitesNetworkInFlight : false
                });
            })
            .catch((e) => {
                console.log(e);
            });
    }

    moveToEditor() {
        window.location.href = "/website/edit";
    }

    onSubscription() {
        // Refresh User status upon successful subscription acquisition
        //this.getWebsiteStatusFromNetwork();
        this.moveToEditor();
    }

    copyLink() {
        let text = WebsitesUtil.getWebsiteUrl(this.state.website.vanityName);
        if (!navigator.clipboard) {
            var textArea = document.createElement("textarea");
            textArea.value = text;

            // Avoid scrolling to bottom
            textArea.style.top = "0";
            textArea.style.left = "0";
            textArea.style.position = "fixed";

            document.body.appendChild(textArea);
            textArea.focus();
            textArea.select();

            try {
                var successful = document.execCommand('copy');
                var msg = successful ? 'successful' : 'unsuccessful';
                console.log('Fallback: Copying text command was ' + msg);

                this.setState({
                    linkCopied : true
                });
            } catch (err) {
                console.error('Fallback: Oops, unable to copy', err);
            }

            document.body.removeChild(textArea);
            return;
        }
        navigator.clipboard.writeText(text).then(() => {
            this.setState({
                linkCopied : true
            })
            console.log('Async: Copying to clipboard was successful!');
        }, (err) => {
            console.error('Async: Could not copy text: ', err);
        });
    }

    render() {
        if (this.state.showBlocker) {
            return (
                <LoadingSpinner/>
            );
        } else if (this.state.viewMode === VIEW_MODE_UNSUBSCRIBED) {
            return(<div>
                <div className="main-wrap">
                    <div className="cell small-12 header">
                        <h1 className="head-title websites">Websites</h1>
                    </div>

                    <div className={"content-body"}>
                        <WebsitesAdvertisement
                            user={this.props.user}
                            onSubscription={this.onSubscription} />
                    </div>
                </div>
            </div>);
        } else {
            let primaryContent = (<LoadingSpinner />);
            if (!this.state.websitesNetworkInFlight) {
                if (this.state.website !== null) {
                    let pendingReviewElement = [];
                    if (this.state.website.pendingReviewCount > 0) {
                        let reviewLabel = "Reviews";
                        if (this.state.website.pendingReviewCount == 1) {
                            reviewLabel = "Review";
                        }

                        pendingReviewElement = (
                            <p>
                                <span className={"website-review-counter"}>
                                    <span className={"count"}>{this.state.website.pendingReviewCount}</span>
                                    <span className={"label"}>New<br/>{reviewLabel}</span>
                                </span>
                            </p>
                        );
                    }

                    let copyButtonLabel = "COPY";
                    if (this.state.linkCopied) {
                        copyButtonLabel = "COPIED";
                    }

                    primaryContent = (
                        <div>
                            <div className={"form-block"}>
                                <div className={"text-center"}>
                                    <h2>Website Manager</h2>
                                    <p>Your website is published and ready to go, give your website to your potential customers, put it on social media and display it on adverts.</p>
                                    <p>
                                        <div className={"url-block"}>{ WebsitesUtil.getWebsiteUrl(this.state.website.vanityName) }</div>
                                        <div className={"url-copy"} onClick={this.copyLink}>{copyButtonLabel}</div>

                                    </p>
                                    <p>Simply click the COPY button and this will copy the link for this website in order for you to point your existing domain name to this new website!</p>
                                    <p>Next, go to your existing web providers control panel and you can then paste this link into the <strong>web forwarding</strong> section.</p>
                                    <p><a className={"ga-button"} href={WebsitesUtil.getWebsiteUrl(this.state.website.vanityName)}>Visit your site</a></p>
                                </div>
                            </div>

                            <div className={"content-block"}>
                                <div className={"equal-block"}>
                                    <h2>Make Changes</h2>
                                    <p>You can make changes to your website at any time. Click the button below to make changes.</p>
                                    <p><span className={"ga-button"} onClick={this.moveToEditor}>Change</span></p>
                                </div>
                                <div className={"equal-block"}>
                                    <h2>Manage Reviews</h2>
                                    <p>When customers give you reviews on your website, you will need to manually approve these reviews for publication on your website. Click the button below to view and manage your customer reviews.</p>
                                    {pendingReviewElement}
                                    <p><span className={"ga-button"} onClick={() => window.location.href = "/" + SECTIONS.WEBSITES + "/reviews/"}>Manage</span></p>
                                </div>
                            </div>

                        </div>
                    )
                } else {
                    primaryContent = (
                        <div>
                            <h2>Get Started</h2>
                            <p>Welcome to your Gas App Website!</p>
                            <p>Start making your website now, click the button below to get started.</p>
                            <p><span className={"ga-button"} onClick={this.moveToEditor}>Start</span></p>
                        </div>
                    );
                }
            }

            return(<div>
                <div className="main-wrap">
                    <div className="cell small-12 header">
                        <h1 className="head-title websites">Websites</h1>
                    </div>

                    <div className={"content-body"}>
                        {primaryContent}
                    </div>
                </div>
            </div>);
        }
    }

}

export default WebsiteLanding;