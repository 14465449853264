import React from 'react'
import HorrorCell from './HorrorCell'
import { API } from '../../api/APIClient'
import Axios from 'axios'
import HorrorDetail from './HorrorDetail'
import HorrorNewPost from './HorrorNewPost'
import InfiniteScroll from 'react-infinite-scroll-component'

class HorrorPosts extends React.Component {

    constructor(props) {
        super(props);

        this.SCREENS = {
            HOME: "home",
            HORROR_DETAIL: "horror",
            HORROR_NEW_POST: "newpost"
        };

        this.state = {
            currentScreen: this.SCREENS.HOME,
            navStack: [],
            horrors: [],
            moreHorror: true,
            lastPostID: 0
        };
    }


    horrorCellClicked(horror)
    {
        this.props.showMenuHandler(false);
        const oldScreen = this.state.currentScreen;
        const navStack = this.state.navStack.concat([oldScreen])
        this.setState({
            currentScreen: this.SCREENS.HORROR_DETAIL,
            navStack: navStack,
            horrorToShow: horror
        });
    }

    newPostClicked()
    {
        this.props.showMenuHandler(false);
        const oldScreen = this.state.currentScreen;
        const navStack = this.state.navStack.concat([oldScreen])
        this.setState({
            currentScreen: this.SCREENS.HORROR_NEW_POST,
            navStack: navStack
        });
    }


    handleBackClick ()
    {
        const navStack = this.state.navStack.slice();

        if (navStack.length > 1)
        {
            this.props.showMenuHandler(false);
        } else {
            this.props.showMenuHandler(true);

        }
        const backScreen = navStack[navStack.length - 1];

        this.setState({
            currentScreen: backScreen,
            navStack: navStack.slice(0,navStack.length - 1)
        });
    }

    getHorrors = () => {
        const formData = new FormData();
        if (this.state.lastPostID > 0)
        {
            formData.append('afterId', this.state.lastPostID);
        }
        formData.append('count', 10);

        Axios
            .post(API.horror.getHorrors, formData)
            .then((res) => {

                if (res.data.success) {

                    var lastPostID = 0;
                    const newHorrors = res.data.data.map((horror, c) => {
                        lastPostID = horror.id
                        return <HorrorCell clickHandler={() => this.horrorCellClicked(horror)} location={horror.location}
                            rating={horror.rating}
                            image={horror.image.thumb}
                            user={horror.givenName + " " + horror.familyName}
                        />;

                    });

                    if (newHorrors.length > 0)
                    {
                        this.setState({
                            horrors:this.state.horrors.concat(newHorrors),
                            lastPostID: lastPostID
                        });

                    } else {
                        this.setState({
                            moreHorror: false
                        });
                    }
                }
            })
    }


    componentDidMount() {

        this.getHorrors();
        this.props.showMenuHandler(true);

    }


    render() {

        switch (this.state.currentScreen) {

            case this.SCREENS.HOME :
                return (
                    <div className="main-wrap">
                        <div className="cell small-12 header horrorposts-header">
                            <h1 className="head-title horror_show">Horror Show</h1>
                            <div onClick={() => this.newPostClicked()} className="ga-button">Create new post</div>

                        </div>
                        <div className="cell small-12">
                            <InfiniteScroll
                                dataLength={this.state.horrors.length}
                                next={this.getHorrors}
                                hasMore={this.state.moreHorror}
                                loader={<h4>Loading...</h4>}
                                endMessage={
                                    <div>
                                    <div className="clearfix"></div>
                                    <p className="end-mes">
                                        That's all we have!
                                    </p>
                                    </div>

                                    }>
                            {this.state.horrors}
                            </InfiniteScroll>

                        </div>
                    </div>
                );

            case this.SCREENS.HORROR_DETAIL :
                return(<HorrorDetail horror={this.state.horrorToShow} backHandler={() => this.handleBackClick()}  />);

            case this.SCREENS.HORROR_NEW_POST :
                return(<HorrorNewPost backHandler={() => this.handleBackClick()}  />);

            default :
                return(<div>unknown screen</div>);
        }
    }
}

export default HorrorPosts;
