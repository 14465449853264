import React from 'react'
import moment from 'moment';

function NewsCell(props) {

    let dateCreated =  moment(props.date, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY');

    return(
        <div className="list-item list-arrow" onClick={props.articleClickHandler}>
            <div className="news-date">{dateCreated}</div>
            <div className="news-title">{props.title}</div>
            <div className="news-desc">{props.description}</div>
        </div>
    );
}

export default NewsCell;
