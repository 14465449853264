import React from 'react'

function HorrorCell(props) {

    var stars = [];
    for (var c=1; c<=5; c++)
    {
        if (props.rating >= c)
        {
            stars.push(<div className="full-star" ></div>);
        } else {
            stars.push(<div className="empty-star" ></div>);

        }
    }

    return(
        <div className="post-cell break-horror">
            <div className="horror-cell" onClick={props.clickHandler}>
                <div class="horror-image">
                    <img src={props.image} alt="Horror Submission"/>
                </div>
                <div className="horror-overlay">
                    <div className="horror-icon"></div>
                    <div className="horror-details">
                        <div className="user">{props.user}</div>
                        <div className="loc">{props.location}</div>
                    </div>
                    <div className="horror-rating">{stars}</div>
                </div>
            </div>
        </div>
    );
}

export default HorrorCell;
