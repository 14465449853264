import React from 'react'

function TalentCell(props) {

    var stars = [];
    for (var c=1; c<=5; c++)
    {
        if (props.rating >= c)
        {
            stars.push(<div className="full-thumb" ></div>);
        } else {
            stars.push(<div className="empty-thumb" ></div>);

        }
    }

    return(
        <div className="cell small-6 medium-4 large-3">
            <div className="talent-cell" onClick={props.clickHandler}>

                <div class="horror-image">

                    <img src={props.image} alt="Talent Submission" /></div>

                <div className="name">{props.user}</div>
                <div className="loc">{props.location}</div>
            </div>
        </div>
    );
}

export default TalentCell;
