import React from 'react'
import BackButton from "../common/BackButton"
import { API } from '../../api/APIClient'
import Axios from 'axios'

class Contacts extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            postcode: ''
        }
        this.waterHardness = [{"id":"1","postcode":"AB","area":"Aberdeen","hardness":"1"},             {"id":"2","postcode":"DD","area":"Dundee","hardness":"1"},
            {"id":"3","postcode":"DG","area":"Dumfries & Galloway","hardness":"1"},             {"id":"4","postcode":"EH","area":"Edinburgh","hardness":"1"},
            {"id":"5","postcode":"FK","area":"Falkirk & Stirling","hardness":"1"},             {"id":"6","postcode":"G","area":"Glasgow","hardness":"1"},
            {"id":"7","postcode":"TD","area":"Galashiels ","hardness":"1"},             {"id":"8","postcode":"KA","area":"Kilmarnock ","hardness":"1"},
            {"id":"9","postcode":"KW","area":"Kirkwall","hardness":"1"},             {"id":"10","postcode":"KY","area":"Kirkcaldy","hardness":"1"},
            {"id":"11","postcode":"ML","area":"Motherwell","hardness":"1"},             {"id":"12","postcode":"PA","area":"Paisley","hardness":"1"},
            {"id":"13","postcode":"PH","area":"Perth","hardness":"1"},             {"id":"14","postcode":"BT","area":"Northern Ireland","hardness":"1"},
            {"id":"15","postcode":"BB","area":"Blackburn","hardness":"2"},             {"id":"16","postcode":"BD","area":"Bradford","hardness":"1"},
            {"id":"17","postcode":"BL","area":"Bolton","hardness":"1"},             {"id":"18","postcode":"CA","area":"Carlisle","hardness":"1"},
            {"id":"19","postcode":"CH","area":"Chester","hardness":"2"},            {"id":"20","postcode":"CW","area":"Crewe","hardness":"2"},
            {"id":"21","postcode":"FY","area":"Blackpool","hardness":"2"},             {"id":"22","postcode":"HD","area":"Huddersfield","hardness":"2"},
            {"id":"23","postcode":"HX","area":"Halifax","hardness":"2"},             {"id":"24","postcode":"L","area":"Liverpool","hardness":"2"},
            {"id":"25","postcode":"LA","area":"Lancaster","hardness":"1"},             {"id":"26","postcode":"M","area":"Manchester","hardness":"2"},
            {"id":"27","postcode":"OL","area":"Oldham","hardness":"2"},            {"id":"28","postcode":"PR","area":"Preston","hardness":"2"},
            {"id":"29","postcode":"SK","area":"Stockport","hardness":"2"},            {"id":"30","postcode":"WA","area":"Warrington","hardness":"2"},
            {"id":"31","postcode":"WN","area":"Wigan","hardness":"2"},             {"id":"32","postcode":"DH","area":"Durham","hardness":"1"},
            {"id":"33","postcode":"DL","area":"Darlington","hardness":"1"},            {"id":"34","postcode":"HG","area":"Harrogate","hardness":"1"},
            {"id":"35","postcode":"HU","area":"Hull","hardness":"3"},             {"id":"36","postcode":"LS","area":"Leeds","hardness":"2"},
            {"id":"37","postcode":"NE","area":"Newcastle upon Tyne","hardness":"2"},             {"id":"38","postcode":"SR","area":"Sunderland","hardness":"1"},
            {"id":"39","postcode":"TS","area":"Cleveland (Teesside)","hardness":"2"},             {"id":"40","postcode":"WF","area":"Wakefield","hardness":"2"},
            {"id":"41","postcode":"YO","area":"York","hardness":"1"},             {"id":"42","postcode":"CF","area":"Cardiff","hardness":"1"},
            {"id":"43","postcode":"LD","area":"Llandrindod Wells","hardness":"2"},             {"id":"44","postcode":"LL","area":"Llandudno","hardness":"1"},
            {"id":"45","postcode":"NP","area":"Newport","hardness":"1"},             {"id":"46","postcode":"SA","area":"Swansea","hardness":"1"},
            {"id":"47","postcode":"SY","area":"Shrewsbury","hardness":"2"},             {"id":"48","postcode":"B","area":"Birmingham","hardness":"2"},
            {"id":"49","postcode":"CV","area":"Coventry","hardness":"2"},             {"id":"50","postcode":"DY","area":"Dudley","hardness":"2"},
            {"id":"51","postcode":"HR","area":"Hereford","hardness":"2"},             {"id":"52","postcode":"NN","area":"Northampton","hardness":"3"},
            {"id":"53","postcode":"ST","area":"Stoke-on-Trent","hardness":"3"},            {"id":"54","postcode":"TF","area":"Telford","hardness":"2"},
            {"id":"55","postcode":"WR","area":"Worcester","hardness":"2"},             {"id":"56","postcode":"WS","area":"Walsall","hardness":"3"},
            {"id":"57","postcode":"WV","area":"Wolverhampton","hardness":"2"},            {"id":"58","postcode":"CB","area":"Cambridge","hardness":"3"},
            {"id":"59","postcode":"CO","area":"Colchester","hardness":"3"},             {"id":"60","postcode":"DE","area":"Derby","hardness":"2"},
            {"id":"61","postcode":"DN","area":"Doncaster","hardness":"3"},             {"id":"62","postcode":"IP","area":"Ipswich","hardness":"3"},
            {"id":"63","postcode":"LE","area":"Leicester","hardness":"2"},            {"id":"64","postcode":"LN","area":"Lincoln","hardness":"3"},
            {"id":"65","postcode":"NG","area":"Nottingham","hardness":"3"},             {"id":"66","postcode":"NR","area":"Norwich","hardness":"3"},
            {"id":"67","postcode":"PE","area":"Peterborough","hardness":"3"},             {"id":"68","postcode":"S","area":"Sheffield","hardness":"3"},
            {"id":"69","postcode":"BA","area":"Bath","hardness":"2"},             {"id":"70","postcode":"BH","area":"Bournemouth","hardness":"3"},
            {"id":"71","postcode":"BS","area":"Bristol","hardness":"3"},             {"id":"72","postcode":"DT","area":"Dorchester","hardness":"3"},
            {"id":"73","postcode":"EX","area":"Exeter","hardness":"1"},             {"id":"74","postcode":"GL","area":"Gloucester","hardness":"3"},
            {"id":"75","postcode":"PL","area":"Plymouth","hardness":"1"},             {"id":"76","postcode":"SN","area":"Swindon","hardness":"3"},
            {"id":"77","postcode":"SP","area":"Salisbury ","hardness":"3"},             {"id":"78","postcode":"TA","area":"Taunton","hardness":"2"},
            {"id":"79","postcode":"TQ","area":"Torquay","hardness":"1"},            {"id":"80","postcode":"TR","area":"Truro","hardness":"1"},
            {"id":"81","postcode":"AL","area":"St Albans","hardness":"3"},            {"id":"82","postcode":"BN","area":"Brighton","hardness":"3"},
            {"id":"83","postcode":"CM","area":"Chelmsford","hardness":"3"},             {"id":"84","postcode":"CT","area":"Canterbury","hardness":"3"},
            {"id":"85","postcode":"GU","area":"Guildford","hardness":"2"},             {"id":"86","postcode":"HP","area":"Hemel Hempstead","hardness":"3"},
            {"id":"87","postcode":"LU","area":"Luton","hardness":"3"},             {"id":"88","postcode":"ME","area":"Medway / Rochester","hardness":"3"},
            {"id":"89","postcode":"MK","area":"Milton Keynes","hardness":"3"},             {"id":"90","postcode":"OX","area":"Oxford","hardness":"3"},
            {"id":"91","postcode":"PO","area":"Portsmouth","hardness":"3"},            {"id":"92","postcode":"RG","area":"Reading","hardness":"3"},
            {"id":"93","postcode":"RH","area":"Redhill","hardness":"3"},            {"id":"94","postcode":"SL","area":"Slough","hardness":"3"},
            {"id":"95","postcode":"SO","area":"Southampton","hardness":"3"},            {"id":"96","postcode":"SS","area":"Southend-on-Sea","hardness":"3"},
            {"id":"97","postcode":"TN","area":"Tonbridge","hardness":"3"},            {"id":"98","postcode":"BR","area":"Bromley","hardness":"3"},
            {"id":"99","postcode":"CR","area":"Croydon","hardness":"3"},             {"id":"100","postcode":"DA","area":"Dartford","hardness":"3"},
            {"id":"101","postcode":"E","area":"London E","hardness":"3"},             {"id":"102","postcode":"EC","area":"London EC","hardness":"3"},
            {"id":"103","postcode":"EN","area":"Enfield","hardness":"3"},             {"id":"104","postcode":"HA","area":"Harrow","hardness":"3"},
            {"id":"105","postcode":"IG","area":"Ilford","hardness":"3"},             {"id":"106","postcode":"KT","area":"Kingston upon Thames","hardness":"2"},
            {"id":"107","postcode":"N","area":"London N","hardness":"3"},             {"id":"108","postcode":"NW","area":"London NW","hardness":"3"},
            {"id":"109","postcode":"SE","area":"London SE","hardness":"3"},             {"id":"110","postcode":"RM","area":"Romford","hardness":"3"},
            {"id":"111","postcode":"SM","area":"Sutton","hardness":"3"},             {"id":"112","postcode":"SW","area":"London SW","hardness":"3"},
            {"id":"113","postcode":"TW","area":"Twickenham","hardness":"3"},            {"id":"114","postcode":"UB","area":"Southall Uxbridge","hardness":"3"},
            {"id":"115","postcode":"W","area":"London W","hardness":"3"},             {"id":"116","postcode":"WC","area":"London WC","hardness":"3"},
            {"id":"117","postcode":"WD","area":"Watford","hardness":"3"},             {"id":"118","postcode":"IV","area":"Inverness","hardness":"1"},
            {"id":"119","postcode":"SG","area":"Stevenage ","hardness":"3"}];

        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event) {
        this.setState({postcode: event.target.value});
    }

    lookupResult()
    {
        var postcodeLetters = "";
        for (var i = 0; i < this.state.postcode.length; i++) {
            const char = this.state.postcode.charAt(i).toUpperCase();
            if (char.match(/[A-Z]/i))
            {
                postcodeLetters += char;
            } else {
                break;
            }
        }

        this.waterHardness.map((area, c) => {

            if (postcodeLetters === area.postcode){

                if (area.hardness === "1")
                {
                    this.setState(
                        {
                            result:{imageClass: "water-soft", text : "Soft to moderately hard", ppm : "0-100 ppm"}
                        }
                    );
                } else if (area.hardness === "2") {
                    this.setState(
                        {
                            result:{imageClass: "water-medium", text : "Slightly hard to moderately hard", ppm : "100-200 ppm"}
                        }
                    );
                }else if (area.hardness === "3") {
                    this.setState(
                        {
                            result:{imageClass: "water-hard", text : "Hard to very hard", ppm : "200+ ppm"}
                        }
                    );
                }

            }

            return null;
        });
    }

    getSponsor = () => {

        Axios
            .get(API.toolbox.getWaterSponsor)
            .then((res) => {
                this.setState({
                    debug: res
                });
                if (res.data.success) {

                    this.setState({
                        sponsor: res.data.data
                        });
                }
            })
    }
    componentDidMount() {

        this.getSponsor();

    }


    render() {

        return (
            <div className="main-wrap">
                <div className="cell small-12 header">
                    <BackButton backHandler={this.props.backHandler} />
                    <h1 className="head-title toolbox">Toolbox > Water PPM</h1>
                </div>
                <div className="cell small-12 ppm">
                    {this.state.sponsor && this.state.sponsor.image && <div class="square-white" style={{cursor: "default"}}><div className="sponsor">Sponsor</div><div className="title-text">{this.state.sponsor.sponsorText}</div><img src={this.state.sponsor.image} alt="Sponsor" /> </div>}
                </div>
                <div className="cell small-12 ppm">
                    <div className="square-white">
                    <div>
                        <div>Enter a postcode and click Search to look up the water hardness results</div>
                        <div className="main-wrap">
                            <div className="cell small-12 medium-8"><input type="text" name="postcode" onChange={this.handleChange} placeholder="Postcode"/></div>
                            <div className="cell small-12 medium-4"><div onClick={() => this.lookupResult()} className="ga-button">Search</div></div>
                        </div>
                        <div className="clearfix"></div>
                    </div>

                    {this.state.result && <div className="">
                        <h3>{this.state.result.text}</h3>
                        <div className={this.state.result.imageClass}></div>
                        <div>{this.state.result.ppm}</div>
                    </div>}
                </div>
                </div>

            </div>
        );
    }
}

export default Contacts;
