import axios from 'axios'

const BASE_URL = "https://gasapp.app-cms.com/"; //LIVE
const VERSION = "1.0.0";
axios.defaults.headers.common['appplatform'] = 'webhub';
axios.defaults.headers.common['appversion'] = VERSION;
// meow

axios.defaults.baseURL = BASE_URL;

export const APIHelper = (function(axios){
    return {
        setAuthDetails: (token, userID) => {
            axios.defaults.headers.common['token'] = token;
            axios.defaults.headers.common['userId'] = userID;
        },
        removeAuthDetails: () => {
            delete axios.defaults.headers.common['token'];
            delete axios.defaults.headers.common['userId'];
        },
        onAuthFailed: (callback) => {
            if (callback instanceof Function) {
                axios.defaults.validateStatus = (status) => {
                    if (status === 401 || status === 403) {
                        callback();
                    }
                    else {
                        return status;
                    }
                }
            }
        },
        parse : (resp) => {
            let out = {
                "success" : false,
                "data" : null,
                "error" : "An unknown error has occurred"
            };

            if (resp !== undefined) {
                if (resp.data.hasOwnProperty("success")) {
                    out.success = resp.data.success;
                }

                if (resp.data.hasOwnProperty("error")) {
                    out.error = resp.data.error;
                }

                if (resp.data.hasOwnProperty("data")) {
                    out.data = resp.data.data;
                }
            }

            return out;
        }
    }
})(axios);

export const API = {
    BASE_URL: BASE_URL,
    auth: {
        login: "auth/login",
        resetPassword: "auth/requestPasswordReset/",
        changeEmail: "auth/changeEmail/",
        changePassword: "auth/changePassword/",
        register: "auth/register/",
        verifyEmail: "auth/verifyEmail",
        resendVerification: "auth/resendVerificationEmail",
        paidByStripe: "auth/paidByStripe"
    },
    user: {
        uploadProfileImage: "user/uploadProfileImage/",
        setProfile: "user/setUserProfileType",
        getSubscriptionData: "user/getUserSubscriptionData/"
    },
    partners : {
        getPartners: "sponsors/listSponsors/"
    },
    referral : {
        getInfo: "referral/getInfoForUser/",
        lookup: "referral/lookup/",
        verify: "referral/verify/"
    },
    competition : {
        getInfo: "competition/getCompetition/",
        submitAnswer: "competition/submitAnswer/"
    },
    newsroom : {
        getArticles: "feeds/getData/"
    },
    appliances: {
        getApplianceTypes: "appliances/getApplianceTypes/",
        getApplianceBrands: "appliances/getApplianceBrands/",
        getAppliances: "appliances/getAppliances"

    },
    horror : {
        getHorrors: "horrorshow/getPosts/",
        submitRating: "horrorshow/submitRating/",
        submitPost: "horrorshow/submitPost/",
        uploadImage: "horrorshow/uploadImage/"
    },
    talent : {
        getTalents: "talentshow/getPosts/",
        submitRating: "talentshow/submitRating/",
        submitPost: "talentshow/submitPost/",
        uploadImage: "talentshow/uploadImage/"
    },
    faultFinder : {
        getPosts: "knowledge/getPosts/",
        getSubscribedPostIDs : "knowledge/subscribedPostIds",
        unsubscribeFromPost : "knowledge/unsubscribeFromPost/",
        subscribeToPost : "knowledge/subscribeToPost/",
        outputPDF: "appliances/outputPDF/",
        editComment: "knowledge/updateCommentBody/",
        editPost: "knowledge/updatePostBody/",
        submitComment: "knowledge/submitComment/",
        uploadCommentImage: "knowledge/uploadCommentImage/",
        markAsAnswer: "knowledge/markAsAnswer/",
        submitPost: "knowledge/submitPost/",
        uploadImage : "knowledge/uploadImage",
        getPost : "knowledge/getPost/"
    },
    breakTime : {
        getPosts: "breaktime/getPostsAndKnowledgebaseAndHorrorShow/",
        getSubscribedPostIDs : "breaktime/subscribedPostIds",
        unsubscribeFromPost : "breaktime/unsubscribeFromPost/",
        subscribeToPost : "breaktime/subscribeToPost/",
        editComment: "breaktime/updateCommentBody/",
        editPost: "breaktime/updatePostBody/",
        submitComment: "breaktime/submitComment/",
        uploadCommentImage: "breaktime/uploadCommentImage/",
        submitPost: "breaktime/submitPost/",
        uploadImage : "breaktime/uploadImage",
        toggleLike: "breaktime/toggleLike/",
        getPost : "breaktime/getPost/",
        getNotifications : "breaktime/getBreaktimeNotificationsForUser/",
        deleteAllNotifications : "breaktime/deleteAllBreaktimeNotifications",
        deleteNotification : "breaktime/deleteBreaktimeNotification",
        markBreaktimeNotificationRead: "breaktime/markBreaktimeNotificationRead",
        getUreadCount: "breaktime/getBreaktimeNotificationCountForUser",
        reportPost: "breaktime/reportPost"
    },
    massive : {
        getDeals: "essentials/getItems/"
    },
    toolbox : {
        getRefPDFs: "referencePDF/getReferencePDF/",
        outputPDF: "referencePDF/outputPDF/",
        getContacts: "contacts/getItems/",
        getWaterSponsor: "sponsors/getWaterSponsor/",
        getVideos: "videos/?page="
    },
    websites : {
        getStatus : "websites/getWebsiteStatus",
        getWebsites : "websites/getUserWebsites",
        getWebsite : "websites/getUserWebsite",
        getTemplates : "websites/getUserWebsiteTemplates",
        getUserWebsiteServiceTypes : "websites/getUserWebsiteServiceTypes",
        getUserWebsiteCardTypes : "websites/getUserWebsiteCardTypes",
        getUserWebsiteBanners : "websites/getWebsiteBanners",
        submitUserWebsite : "websites/submitUserWebsite",
        submitWebsiteImage : "websites/submitWebsiteImage",
        getUserWebsiteReviews : "websites/getUserWebsiteReviews",
        publishUserWebsiteReview : "websites/publishUserWebsiteReview",
        deleteUserWebsiteReview : "websites/deleteUserWebsiteReview",
        submitStripePayment : "websites/submitStripePayment",
        getWebsiteSubscription : "websites/getWebsiteSubscription",
        cancelSubscription : "websites/cancelSubscription",
        autofillCounty : "websites/autofillCounty",
        submitSponsorData : "websites/submitSponsorData",
        getSponsor : "websites/getSponsor"
    }
};
