import React from 'react'
import PostUserInfo from '../sharedBreakFault/PostUserInfo'
import PostImages from '../sharedBreakFault/PostImages'
import PostFooter from '../sharedBreakFault/PostFooter'


class PostCell extends React.Component {

    constructor(props) {
        super(props);


        this.state = {
            isSubbed: this.props.isSubbed
        };
    }

    unSubClicked (e)
    {
        this.props.subHandler(this.props.post.id, false)
        this.setState({isSubbed: false});
        e.stopPropagation();

    }
    subClicked (e)
    {
        this.props.subHandler(this.props.post.id, true)
        this.setState({isSubbed: true});
        e.stopPropagation();
    }

    render()
    {

        return(
            
                <div className="post-cell fault-post-cell" onClick={this.props.clickHandler}>
                    <div className="fault-wrap">
                        <div className="notifications">{this.state.isSubbed ?
                            <div onClick={e => this.unSubClicked(e)} className="bell-icon notif-full" /> :
                            <div onClick={e => this.subClicked(e)}className="bell-icon notif-empty" />}
                        </div>
                        <PostUserInfo profileImageThumb={this.props.post.profileImageThumb}
                            profileImage={this.props.post.profileImage}
                            givenName={this.props.post.givenName}
                            familyName={this.props.post.familyName}
                            isInstaller={this.props.post.isInstaller}
                            isBreakdown={this.props.post.isBreakdown}
                            isMisc={this.props.post.isMisc}
                            imageHandler={this.props.imageHandler}
                            isApprentice={this.props.post.isApprentice}
                            isTrainingCentreUser={this.props.post.isTrainingCentreUser}
                            hasValidGasCard={this.props.post.hasValidGasCard}
                            dateCreated={this.props.post.dateCreated} />

                        <div className="pdf-link">{this.props.post.applianceType} > {this.props.post.applianceBrand} > {this.props.post.applianceName}</div>
                        <div className="title">{this.props.post.title}</div>
                        <div className="body">{this.props.post.body}</div>
                        <PostImages images={this.props.post.images}
                            imageHandler={this.props.imageHandler}
                        />
                        <PostFooter commentCount={this.props.post.commentCount} />
                    </div>
                </div>
            
        );
    }

}

export default PostCell;
