import React from 'react'
import { API } from '../../api/APIClient'
import Axios from 'axios'
import ChangeEmail from '../settings/ChangeEmail'


class EmailVerify extends React.Component {

    constructor(props) {
        super(props);


        this.SCREENS = {
            HOME: "verify",
            CHANGE_EMAIL: "changeMyEmail"
        };

        this.state = {
            currentScreen: this.SCREENS.HOME,
            code: "",
            submitting:false,
            navStack: [],
            resending: false
        };

        this.handleChange = this.handleChange.bind(this);
    }




    handleChange(event) {

        const target = event.target;
        const value = target.value;
        const name = target.name;

        if (name === "referCode")
        {
            this.setState({
                [name]: value
            }, () => this.validateReferral());
        } else {
            this.setState({
                [name]: value
            });
        }

    }

    verifyClicked()
    {
        if (this.state.code.length > 0)
        {
            const formData = new FormData();
            formData.append('code', this.state.code);
            this.setState({
                submitting:true,
                submitMessage:"Sending..."
            });
            Axios
                .post(API.auth.verifyEmail, formData)
                .then((res) => {

                    if (res.data.success ) {

                        alert("Verified")
                        var updatedUsr = this.props.user
                        updatedUsr.emailVerified = 1
                        this.props.userHandler(updatedUsr)
                    }
                    this.setState({
                        resending:false,
                        submitMessage:""
                    });


                }).catch(error => {

                    this.setState({
                        submitting:false,
                        submitMessage:""
                    });

                    console.log(error.response)
                    if (error.response.data.error) {

                        alert(error.response.data.error)
                    } else{
                        alert("Could not verify email.  Please check connection and try again.")

                    }
                });
        } else {
            alert("Please enter the verification code.")

        }

    }

    resendClicked()
    {
        const formData = new FormData();
        this.setState({
            resending:true,
            submitMessage:"Sending..."
        });
        Axios
            .post(API.auth.resendVerification, formData)
            .then((res) => {

                if (res.data.success ) {

                    alert("Verification email sent successfully.")
                }
                this.setState({
                    resending:false,
                    submitMessage:""
                });


            }).catch(error => {

                this.setState({
                    resending:false,
                    submitMessage:""
                });

                console.log(error.response)
                if (error.response.data.error) {

                    alert(error.response.data.error)
                } else{
                    alert("Could not resend email.  Please check connection and try again.")

                }
            });
    }


    handleBackClick ()
    {
        const navStack = this.state.navStack.slice();

        const backScreen = navStack[navStack.length - 1];

        this.setState({
            currentScreen: backScreen,
            navStack: navStack.slice(0,navStack.length - 1)
        });
    }


    changeEmailClicked ()
    {
        const oldScreen = this.state.currentScreen;
        const navStack = this.state.navStack.concat([oldScreen])
        this.setState({
            currentScreen: this.SCREENS.CHANGE_EMAIL,
            navStack: navStack
        });
    }
    render() {

        switch (this.state.currentScreen) {

            case this.SCREENS.CHANGE_EMAIL :
                return (<ChangeEmail backHandler={() => this.handleBackClick()}  />);

            case this.SCREENS.HOME :

                return (
                    <div className="loginMain">
                        <div className="main-wrap">
                            <div  style={{width: "100%",padding:"12px",textAlign:"right"}}>
                                <span onClick={this.props.logoutHandler}  style={{cursor: "pointer",color:"white"}}>Log Out</span>
                            </div>
                            <div className="reg-box">
                                <h1 className="head-title ">Email Verification</h1>

                                <div className="main-wrap">

                                    <div className="cell small-12 ">
                                        We've sent an email with a verification code to the email address you used to sign up.  Please enter the code in the box below.
                                    </div>
                                    <div className="cell small-12 ">
                                        <div className="cell small-6">
                                            <label>
                                                <input className="reg-field" type="text" name="code" placeholder="Verification Code" value={this.state.code} onChange={this.handleChange}/>
                                            </label>
                                        </div>
                                        <div className="cell small-6">
                                {this.state.submitting ? this.state.submitMessage : <div onClick={() => this.verifyClicked()} className="ga-button">Verify</div>}
                                        </div>
                                    </div>
                                    <div className="cell small-12 ">
                                        If you used the wrong email address, or want to re-send the verification email, please use the buttons below.
                                    </div>
                                    <div className="cell small-12">
                                        <div onClick={() => this.changeEmailClicked()} className="ga-button">Edit Email Address</div>
                                    </div>
                                    <div className="cell small-12">
                                {this.state.resending ? this.state.submitMessage : <div onClick={() => this.resendClicked()} className="ga-button">Resend Verification Email</div>}
                                    </div>
                                    <div className="clearfix"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            default :
                return <div>Unknown screen</div>
        }
    }
}

export default EmailVerify;
