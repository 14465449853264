import React, {Component} from 'react';
import StripeCheckout from 'react-stripe-checkout';
import { API } from '../../api/APIClient'
import Axios from 'axios'

class Payment extends Component {


    onToken = (token, addresses) => {

        //this.setState({token: token, addresses: addresses});

        const formData = new FormData();
        formData.append('stripeEmail', token.email);
        formData.append('stripeToken', token.id);
        formData.append('stripeTokenType', token.type);
        if (this.props.term ==="year")
        {
            //formData.append('stripePlan', "plan_FPSFskm551U08p");//dev
            formData.append('stripePlan', "plan_FTzfGRerpMUxfD");//live

        } else {
            //formData.append('stripePlan', "plan_FPSFskm551U08p");//dev
            formData.append('stripePlan', "plan_FTzRQfSBWwXwgR");//live
        }

        Axios
            .post(API.auth.paidByStripe, formData)
            .then((res) => {

                if (res.data.success) {

                    alert("Thanks, your subscription has been registered.");

                    this.props.refreshSubDetails();

                }
            }).catch(error => {


                console.log(error.response)
                if (error.response.data.error) {

                    alert(error.response.data.error)
                } else{
                    alert("Could not create subscription.  Please check connection and try again.")

                }
            });


    };


    render() {
        let descr = "12 month Subscription"
        let price = 2500
        if (this.props.term ==="month")
        {
            descr = "1 month Subscription"
            price = 300

        }
        return (
                <div className="checkout">
                    <StripeCheckout
                        //stripeKey="pk_test_dh90eeaTCUJVLBItOKLqKkln" //dev
                        stripeKey="pk_live_09ruKK0qXpwnqFzI5xVnHcbM"//
                        token={this.onToken}
                        image="https://gasapp.app-cms.com/assets/img/flame.png"
                        name="Gas App Uk Subscription"
                        description={descr}
                        amount={price}
                        email={this.props.email}
                        currency="gbp"
                        locale="auto"
                        label="Pay Now"
                    />
            </div>
        );
    }
}

export default Payment;