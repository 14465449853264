import React from 'react';
import StripeCheckout from "react-stripe-checkout";
import Axios from "axios";
import {API, APIHelper} from "../../api/APIClient";

import "../../assets/css/websites-advertisement.css";

import tick from '../../assets/img/websites_tick.svg';
import template1 from '../../assets/img/template_view_1.jpg';
import template1b from '../../assets/img/template_view_1b.jpg';
import template2 from '../../assets/img/template_view_2.jpg';
import template2b from '../../assets/img/template_view_2b.jpg';
import template3 from '../../assets/img/template_view_3.jpg';
import template3b from '../../assets/img/template_view_3b.jpg';
import moment from "moment";
import SimpleAlertDialog, {ModalButton} from "../common/SimpleAlertDialog";
import LoadingSpinner from "../common/LoadingSpinner";

class WebsitesAdvertisement extends React.Component {

    constructor(props, context) {
        super(props, context);

        this.state = {
            currentTemplateIndex : 0,
            sponsorDataName : null,
            sponsorDataEmail : null,
            sponsorDataMobile : null,
            sponsorDataAgreement : false,
            sponsorDataSubscribe : false,
            modalOpen : false,
            modalTitle : null,
            modalMessage : null,
            modalButtons : [],
            headlineSponsor : null,
            headlineSponsorNetworkInFlight : false
        };

        this.templates = [
            {
                image : template1,
                caption : "Template 1 of 3 - About"
            },
            {
                image : template1b,
                caption : "Template 1 of 3 - Gallery & Reviews"
            },
            {
                image : template2,
                caption : "Template 2 of 3 - About"
            },
            {
                image : template2b,
                caption : "Template 2 of 3 - Gallery & Reviews"
            },
            {
                image : template3,
                caption : "Template 3 of 3 - About"
            },
            {
                image : template3b,
                caption : "Template 3 of 3 - Gallery & Reviews"
            }
        ];

        this.plusSlides = this.plusSlides.bind(this);
        this.currentSlide = this.currentSlide.bind(this);
        this.onToken = this.onToken.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.registerSponsorData = this.registerSponsorData.bind(this);
        this.validateSponsorForm = this.validateSponsorForm.bind(this);
        this.getHeadlineSponsor = this.getHeadlineSponsor.bind(this);
    }

    componentDidMount() {
        this.getHeadlineSponsor();
    }

    plusSlides(position) {
        let newPos = this.state.currentTemplateIndex + position;

        if (newPos < 0) {
            newPos = this.templates.length - 1;
        } else if (newPos >= this.templates.length) {
            newPos = 0;
        }

        this.setState({
            currentTemplateIndex : newPos
        });
    }

    currentSlide(position) {
        this.setState({
            currentTemplateIndex : position
        });
    }

    /**
     * Handler for input items to ensure the value of the input is put into the state
     * @param event     Event from the onChange property
     */
    handleChange(event) {
        const target = event.target;
        const name = target.name;

        let value = target.value;
        if (event.target.type === "checkbox") {
            value = event.target.checked ? 1 : 0
        }

        this.setState({
            [name] : value
        });
    }

    onToken(token) {
        let formData = new FormData();
        formData.append("stripeToken", token.id);
        formData.append("type", token.type);
        formData.append("email", token.email);

        Axios.post(API.websites.submitStripePayment, formData)
            .then((r) => {
                let resp = APIHelper.parse(r);
                if (resp.success) {
                    console.log("SUBSCRIPTION SUBMIT OK!");
                    if (this.props.onSubscription !== undefined) {
                        this.props.onSubscription();
                    }
                } else {
                    console.log(resp.error);
                }
            })
            .catch((e) => {
                console.log(e);
            });
    }

    getHeadlineSponsor() {
        this.setState({
            headlineSponsorNetworkInFlight : true
        });

        Axios.get(API.websites.getSponsor)
            .then((r) => {
                let resp = APIHelper.parse(r);
                if (resp.success) {
                    this.setState({
                        headlineSponsor : resp.data,
                        headlineSponsorNetworkInFlight : false
                    });
                } else {
                    console.log(resp.error);
                }
            })
            .catch((e) => {
                console.log(e);
            });
    }

    registerSponsorData() {
        if (!this.validateSponsorForm()) {
            console.log("Validation failed. Aborting.");
            return;
        }

        let data = {
            name : this.state.sponsorDataName,
            emailAddress : this.state.sponsorDataEmail,
            mobileNumber : this.state.sponsorDataMobile,
            shareDetails : this.state.sponsorDataAgreement,
            subscribe : this.state.sponsorDataSubscribe
        };

        let formData = new FormData();
        formData.append("data", JSON.stringify(data));

        Axios.post(API.websites.submitSponsorData, formData)
            .then((r) => {
                let resp = APIHelper.parse(r);
                if (resp.success) {
                    console.log("SPONSOR DATA SUBMITTED!");
                    if (this.props.onSubscription !== undefined) {
                        this.props.onSubscription();
                    }
                } else {
                    console.log("SPONSOR DATA ERROR!");
                    console.log(resp.error);
                    this.showError("An error has occurred. Please try again later.");
                }
            })
            .catch((e) => {
                console.log(e);
                this.showError("A fatal error has occurred. Please reload the page.");
            });
    }

    validateSponsorForm() {
        let validationData = [
            { keyName : "sponsorDataName", label : "Please provide your Name" },
            { keyName : "sponsorDataEmail", label : "Please provide your Email Address" },
            { keyName : "sponsorDataMobile", label : "Please provide your Mobile Number" }
        ];

        for (let i = 0; i < validationData.length; i++) {
            let validationItem = validationData[i];
            if (this.state[validationItem.keyName] === null || this.state[validationItem.keyName] === "") {
                this.showError(validationItem.label);
                return false;
            }
        }

        /*if (!this.state.sponsorDataAgreement) {
            this.showError("You must agree to the terms and conditions");
            return false;
        }*/

        return true;
    }

    showError(message) {
        this.showAlert("Error", message, [
            {
                label : "OK",
                onClick : () => {
                    this.dismissAlert();
                }
            }
        ]);
    }

    showAlert(title, message, buttons) {
        this.setState({
            modalOpen : true,
            modalTitle : title,
            modalMessage : message,
            modalButtons : buttons
        });
    }

    dismissAlert() {
        this.setState({
            modalOpen : false
        });
    }

    render() {
        let imageDots = [];

        for (let i = 0; i < this.templates.length; i++) {
            let extraClass = "";
            if (this.state.currentTemplateIndex == i) {
                extraClass = " active";
            }

            imageDots.push(
                <span className={"dot" + extraClass} onClick={() => this.currentSlide(i)} />
            );
        }

        let sponsorFormElem = (<LoadingSpinner />);
        if (!this.state.headlineSponsorNetworkInFlight && this.state.headlineSponsor != null) {
            sponsorFormElem = (
                <center>
                    <h3>Sign up today!</h3>
                    <p>Provide the details below and get started!</p>

                    <div className={"sponsor-form"}>
                        <div className={"form-block"}>
                            <label>Name</label>
                            <input type={"text"} className={"form-control"} name={"sponsorDataName"} value={this.state.sponsorDataName} onChange={this.handleChange} />
                        </div>

                        <div className={"form-block"}>
                            <label>Email Address</label>
                            <input type={"text"} className={"form-control"} name={"sponsorDataEmail"} value={this.state.sponsorDataEmail} onChange={this.handleChange} />
                        </div>

                        <div className={"form-block"}>
                            <label>Mobile Number</label>
                            <input type={"text"} className={"form-control"} name={"sponsorDataMobile"} value={this.state.sponsorDataMobile} onChange={this.handleChange} />
                        </div>

                        <div className={"bordered-content"}>
                            <div className={"top-content"}>
                                We would like to share your contact details with our sponsor, {this.state.headlineSponsor.name}, so that they can provide you with an insurance renewal quote and send you information about their goods and services which they believe may be of interest to you. If you are happy for us to share your details, please tick the relevant box below. You can find details of how {this.state.headlineSponsor.name} will use your data in their <a href={this.state.headlineSponsor.termsUrl} target={"_blank"}>privacy policy</a>. If you wish to unsubscribe at any time you can do so directly with {this.state.headlineSponsor.name} using the unsubscribe link in any email communication.
                            </div>

                            <div className={"form-block"}>
                                <label><input type={"checkbox"} name={"sponsorDataAgreement"} onChange={this.handleChange} checked={this.state.sponsorDataAgreement} /> Yes, I am happy for my contact details to be shared with {this.state.headlineSponsor.name} for the purposes of receiving an insurance renewal quote.</label>
                            </div>

                            <div className={"form-block"}>
                                <label><input type={"checkbox"} name={"sponsorDataSubscribe"} onChange={this.handleChange} checked={this.state.sponsorDataSubscribe} /> Yes, I would like to subscribe to receive regular email updates from {this.state.headlineSponsor.name}. I understand I can unsubscribe at any time.</label>
                            </div>
                        </div>

                        <div className={"form-block text-center"}>
                            <span className={"ga-button"} onClick={this.registerSponsorData}>Create My Website</span>
                        </div>

                        <div className={"text-center"}>
                            <a href={this.state.headlineSponsor.footerUrl} target={"_blank"}><img src={this.state.headlineSponsor.imageUrl} alt={"Powered by " + this.state.headlineSponsor.name} /></a>
                        </div>
                    </div>

                    <p><a href="https://gasapp.app-cms.com/info/policy_index/" target="top">Terms &amp; Conditions</a></p>

                </center>
            );
        }

        return (
            <div className={"what-you-get"}>
                <div className="cell small-12">
                    <center><h3>Why choose Gas App to build your business website?</h3></center>
                </div>

                <div className="slideshow-container">

                    <div className="slide fade">
                        <div className="numbertext">{this.templates[this.state.currentTemplateIndex].caption}</div>
                        <img src={this.templates[this.state.currentTemplateIndex].image} alt={"Image"}/>
                    </div>

                    <a className={"prev"} onClick={() => this.plusSlides(-1)}>&#10094;</a>
                    <a className={"next"} onClick={() => this.plusSlides(1)}>&#10095;</a>

                </div>

                <div className="slide-dots">
                    {imageDots}
                </div>


                <div className="cell small-12 medium-6">
                    <div className="tick-wrap">
                        <img src={tick} alt=""/>
                            <div className="tick-text">
                                <span className="tick-title">Professional website including hosting completely FREE of charge! No catches, No gimmicks!</span>
                            </div>
                    </div>
                </div>

                <div className="cell small-12 medium-6">
                    <div className="tick-wrap">
                        <img src={tick} alt=""/>
                            <div className="tick-text">
                                <span className="tick-title">Have your new website live in less than 5 minutes</span>
                            </div>
                    </div>
                </div>

                <div className="cell small-12 medium-6">
                    <div className="tick-wrap">
                        <img src={tick} alt=""/>
                            <div className="tick-text">
                                <span className="tick-title">Choose from any of the 3 striking and impressive templates we offer – when you’re ready for a new look just change to another template in seconds.</span>
                            </div>
                    </div>
                </div>

                <div className="cell small-12 medium-6">
                    <div className="tick-wrap">
                        <img src={tick} alt=""/>
                            <div className="tick-text">
                                <span className="tick-title">Fully customizable by adding your own business logo<br /><br /><br /></span>
                            </div>
                    </div>
                </div>

                <div className="cell small-12 medium-6">
                    <div className="tick-wrap">
                        <img src={tick} alt=""/>
                            <div className="tick-text">
                                <span className="tick-title">Showcase your work in the gallery page - add and remove images to your gallery instantly</span>
                            </div>
                    </div>
                </div>

                <div className="cell small-12 medium-6">
                    <div className="tick-wrap">
                        <img src={tick} alt=""/>
                            <div className="tick-text">
                                <span className="tick-title">Full access to update all pages quickly and easily - 24 / 7</span>
                            </div>
                    </div>
                </div>

                <div className="cell small-12 medium-6">
                    <div className="tick-wrap">
                        <img src={tick} alt=""/>
                            <div className="tick-text">
                                <span className="tick-title">No ongoing fees to change or amend your website</span>
                            </div>
                    </div>
                </div>

                <div className="cell small-12 medium-6">
                    <div className="tick-wrap">
                        <img src={tick} alt=""/>
                            <div className="tick-text">
                                <span className="tick-title">Gain customers confidence with the reviews page where reviews can be added instantly once you’ve approved them</span>
                            </div>
                    </div>
                </div>

                <div className="cell small-12 medium-6">
                    <div className="tick-wrap">
                        <img src={tick} alt=""/>
                            <div className="tick-text">
                                <span className="tick-title">Point your existing domain name to this new website</span>
                            </div>
                    </div>
                </div>

                <div className="cell small-12 medium-6">
                    <div className="tick-wrap">
                        <img src={tick} alt=""/>
                            <div className="tick-text">
                                <span className="tick-title">Fully mobile and computer friendly web pages</span>
                            </div>
                    </div>
                </div>



                <div className="cell small-12 medium-6">
                    <div className="tick-wrap">

                        <div className="tick-text">
                            <span className="tick-title" />
                        </div>
                    </div>
                </div>

                <div className="grid-x grid-padding-x">
                    <div className="cell small-12 small-offset-0 medium-8 medium-offset-2 sign-up-box">
                        {sponsorFormElem}
                    </div>
                </div>

                <SimpleAlertDialog
                    open={this.state.modalOpen}
                    title={this.state.modalTitle}
                    message={this.state.modalMessage}>
                    {
                        this.state.modalButtons.map((item) => (
                            <ModalButton label={item.label} className={item.className} onClick={item.onClick} />
                        ))
                    }
                </SimpleAlertDialog>

            </div>
        )
        //<a href="" className="ga-button">Sign up now!</a>
    }

}

/*
<h3>Sign up today!</h3>
<p>Sign up for only £15 + VAT per month recurring until cancelled.</p>
<StripeCheckout
    //stripeKey="pk_test_dh90eeaTCUJVLBItOKLqKkln" //dev
    stripeKey="pk_live_09ruKK0qXpwnqFzI5xVnHcbM"// live
    //stripeKey={"pk_test_iabuoNdy2gLsCMsntyNwa9Jx"} // Chris test
    token={this.onToken}
    image="https://gasapp.app-cms.com/assets/img/flame.png"
    name="Gas App Uk Websites Subscription"
    description={"Gas App Uk Websites Monthly Subscription"}
    amount={1800}
    email={this.props.user.email}
    currency="gbp"
    locale="auto"
    label="Sign Up and Pay Now" />
<p><a href="https://gasapp.app-cms.com/info/policy_index/" target="top">Terms &amp; Conditions</a></p>

 */

export default WebsitesAdvertisement;