import React from 'react'
import MenuItem from './MenuItem'
import {SECTIONS} from '../../resources/strings'
import { Link } from "react-router-dom";
import Swiper from 'react-id-swiper';

function Menu(props) {

    function iconClassForSection(section) {

        switch (section) {
            case SECTIONS.PARTNERS:
                return "menu-icon-partners";
            case SECTIONS.TOOLBOX:
                return "menu-icon-toolbox";
            case SECTIONS.OFFICE:
                return "menu-icon-office";
            case SECTIONS.REFERRALS:
                return "menu-icon-referral";
            case SECTIONS.BREAK_TIME:
                return "menu-icon-breaktime";
            case SECTIONS.COMPETITION:
                return "menu-icon-competition";
            case SECTIONS.MASSIVE_DEALS:
                return "menu-icon-massive";
            case SECTIONS.FAULT_FINDING:
                return "menu-icon-fault";
            case SECTIONS.HORROR_SHOW:
                return "menu-icon-horror";
            case SECTIONS.TALENT_SHOW:
                return "menu-icon-talent";
            case SECTIONS.NEWSROOM :
                return "menu-icon-news";
            case SECTIONS.WEBSITES:
                return "menu-icon-websites";
            default:
                return "menu-icon-partners";
        };

    }

    function nameForSection(section) {

        switch (section) {
            case SECTIONS.PARTNERS:
                return "Partners";
            case SECTIONS.TOOLBOX:
                return "Tool Box";
            case SECTIONS.OFFICE:
                return "Office";
            case SECTIONS.REFERRALS:
                return "Points Store";
            case SECTIONS.BREAK_TIME:
                return "Break Time";
            case SECTIONS.COMPETITION:
                return "Competition";
            case SECTIONS.MASSIVE_DEALS:
                return "Massive Deals";
            case SECTIONS.FAULT_FINDING:
                return "Fault Finding";
            case SECTIONS.HORROR_SHOW:
                return "Horror Show";
            case SECTIONS.TALENT_SHOW:
                return "Talent Show";
            case SECTIONS.NEWSROOM :
                return "News Room";
            case SECTIONS.WEBSITES:
                return "Website Manager";
            default:
                return "...";
        };

    }


//Swiper
    const params = {
        slidesPerView: 'auto',
        grabCursor:true,
        navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev'
      }
    };

    return(
        <div className="nav-wrap">
            <div className="nav-box">
                <Swiper {...params}>
                    <Link to={`/` + SECTIONS.PARTNERS}><MenuItem itemName={nameForSection(SECTIONS.PARTNERS)} iconClass={iconClassForSection(SECTIONS.PARTNERS)} /></Link>
                    <Link to={`/` + SECTIONS.TOOLBOX}><MenuItem itemName={nameForSection(SECTIONS.TOOLBOX)} iconClass={iconClassForSection(SECTIONS.TOOLBOX)} /></Link>
                    <Link to={`/` + SECTIONS.OFFICE}><MenuItem itemName={nameForSection(SECTIONS.OFFICE)} iconClass={iconClassForSection(SECTIONS.OFFICE)}/></Link>
                    <Link to={`/` + SECTIONS.COMPETITION}><MenuItem itemName={nameForSection(SECTIONS.COMPETITION)} iconClass={iconClassForSection(SECTIONS.COMPETITION)} /></Link>
                    <Link to={`/` + SECTIONS.BREAK_TIME}><MenuItem itemName={nameForSection(SECTIONS.BREAK_TIME)} iconClass={iconClassForSection(SECTIONS.BREAK_TIME)} /></Link>
                    <Link to={'/' + SECTIONS.WEBSITES}><MenuItem itemName={nameForSection(SECTIONS.WEBSITES)} iconClass={iconClassForSection(SECTIONS.WEBSITES)} /></Link>
                    <Link to={`/` + SECTIONS.FAULT_FINDING}><MenuItem itemName={nameForSection(SECTIONS.FAULT_FINDING)} iconClass={iconClassForSection(SECTIONS.FAULT_FINDING)} /></Link>
                    <Link to={`/` + SECTIONS.NEWSROOM}><MenuItem itemName={nameForSection(SECTIONS.NEWSROOM)} iconClass={iconClassForSection(SECTIONS.NEWSROOM)} /></Link>
                    <Link to={`/` + SECTIONS.TALENT_SHOW}><MenuItem itemName={nameForSection(SECTIONS.TALENT_SHOW)} iconClass={iconClassForSection(SECTIONS.TALENT_SHOW)} /></Link>
                    <Link to={`/` + SECTIONS.HORROR_SHOW}><MenuItem itemName={nameForSection(SECTIONS.HORROR_SHOW)} iconClass={iconClassForSection(SECTIONS.HORROR_SHOW)} /></Link>
                    <Link to={`/` + SECTIONS.MASSIVE_DEALS}><MenuItem itemName={nameForSection(SECTIONS.MASSIVE_DEALS)} iconClass={iconClassForSection(SECTIONS.MASSIVE_DEALS)} /></Link>
                </Swiper>
            </div>
        </div>
    );
}

export default Menu;
