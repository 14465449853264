import React from 'react'
import SectionButton from '../common/SectionButton'
import ReferencePDFs from './ReferencePDFs'
import Contacts from './Contacts'
import WaterPPM from './WaterPPM'
import Videos from './Videos'

class ToolboxHome extends React.Component {

    constructor(props) {
        super(props);

        this.SCREENS = {
            HOME : "home",
            REFERENCE_PDFS : "refpdfs",
            CONTACTS : "contacts",
            VIDEOS: "videos",
            WATER_PPM : "water ppm"
        };

        this.state = {
            currentScreen: this.SCREENS.HOME,
            navStack: []
        };
    }


    handleListItemClick (newScreen)
    {
        this.props.showMenuHandler(false);
        const oldScreen = this.state.currentScreen;
        const navStack = this.state.navStack.concat([oldScreen])
        this.setState({
            currentScreen: newScreen,
            navStack: navStack
        });
    }



    handleBackClick ()
    {
        const navStack = this.state.navStack.slice();

        if (navStack.length > 1)
        {
            this.props.showMenuHandler(false);
        } else {
            this.props.showMenuHandler(true);

        }
        const backScreen = navStack[navStack.length - 1];

        this.setState({
            currentScreen: backScreen,
            navStack: navStack.slice(0,navStack.length - 1)
        });
    }

    componentDidMount()
    {
        this.props.showMenuHandler(true);

    }

    render() {

        switch (this.state.currentScreen) {

            case this.SCREENS.HOME :
                return (
                    <div className="main-wrap">
                        <div className="cell small-12 header">
                            <h1 className="head-title toolbox">Toolbox</h1>
                        </div>
                        <div className="cell small-12">
                            <SectionButton title="Reference PDFs" sectionClick={() => this.handleListItemClick(this.SCREENS.REFERENCE_PDFS)} iconClass="icon-reference"/>
                            <SectionButton title="Videos" sectionClick={() => this.handleListItemClick(this.SCREENS.VIDEOS)} iconClass="icon-vid"/>
                            <SectionButton title="Contacts" sectionClick={() => this.handleListItemClick(this.SCREENS.CONTACTS)} iconClass="icon-phone"/>
                            <SectionButton title="Water PPM" sectionClick={() => this.handleListItemClick(this.SCREENS.WATER_PPM)} iconClass="icon-tap"/>
                        </div>
                    </div>
                );

            case this.SCREENS.REFERENCE_PDFS :
                return(<ReferencePDFs backHandler={() => this.handleBackClick()}  />);

            case this.SCREENS.CONTACTS :
                return(<Contacts backHandler={() => this.handleBackClick()}  />);

            case this.SCREENS.WATER_PPM :
                return(<WaterPPM backHandler={() => this.handleBackClick()}  />);

            case this.SCREENS.VIDEOS :
                return(<Videos backHandler={() => this.handleBackClick()}  />);

            default :
                return(<div>unknown screen</div>);
        }
    }
}

export default ToolboxHome;
