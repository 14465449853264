import React from 'react'

function Partner(props) {


    if (props.typeID == 1)
    {
        return(
            <div className="cell small-12">
                <div className="square-white" onClick={()=> window.open(props.url, "_blank")} >
                    <div className="part-thumb">
                        <img src={props.image} alt="Partner" />
                    </div>
                    <div className="part-desc">
                        <h2>{props.title}</h2>
                        <p>{props.description}</p>
                    </div>

                </div>
            </div>
        );
    } else {
        return(
            <div className="cell small-12 medium-6">
                <div className="square-white small" onClick={()=> window.open(props.url, "_blank")} >
                    <div className="part-thumb">
                        <img src={props.image} alt="Partner" />
                    </div>
                    <div className="part-desc">
                        <h2>{props.title}</h2>
                        <p>{props.description}</p>
                    </div>
                </div>
            </div>
        );
    }

}

export default Partner;
