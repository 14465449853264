import React from 'react'
import Partner from './Partner'
import { API } from '../../api/APIClient'
import Axios from 'axios'

class PartnersList extends React.Component  {
    constructor(props) {
        super(props);

        this.state = {
            partners: [],
            isLoading: true,
            debug: {}
        }
    }


    getPartners = () => {

        Axios
            .get(API.partners.getPartners)
            .then((res) => {

                if (res.data.success) {
                        this.setState({
                            partners: res.data.data.map((partner, c) => {

                                return <Partner title={partner.title}
                                    image={partner.image}
                                    url={partner.url}
                                    typeID={partner.sponsorTypeId}
                                    description={partner.description}  />;
                            })
                    })
                }

            }).catch(error => {

                if (error.response.data.error && error.response.data.error === "Not Authorised") {
                    //log out
                    this.props.logoutHandler();
                }
            });
    }
    componentDidMount() {
        this.getPartners();
        this.props.showMenuHandler(true);

    }

    render() {

        return (
            <div className="main-wrap">

                <div className="cell small-12 header">
                    <h1 className="head-title partners">Partners</h1>
                </div>
                <div className="main-wrap partner-list">
                
        {this.state.partners}

                </div>

            </div>
        );
    }
}

export default PartnersList;
