import React from 'react'

class PostFooter extends React.Component {

    constructor(props) {
        super(props);


        this.state = {
            liked: this.props.liked,
            likeCount: this.props.likeCount
        };
    }



    figureOutLikesBit()
    {
        var likesBit = ""
        if (this.props.likeCount !== undefined) {

            const liketStr = this.state.likeCount == 1 ? "Like" : "Likes";

            const likesIcon = this.state.liked ? <div className="like-icon full-like" /> : <div className="like-icon empty-like" />

            likesBit = <span className="likespan" onClick={e => this.likeTapped(e)}>{likesIcon} {this.state.likeCount} {liketStr}</span>
        }
        return likesBit
    }


    render() {
        if (this.props.showReply) {

            var likesBitReply = this.figureOutLikesBit();

            return (
                <div className="footer">
                    {likesBitReply}
                    <div className="footer-btn" onClick={this.props.replyClickHandler}>
                        <div className="comment-icon empty-comment" />
                        Reply</div>

                {this.props.showAccept && <div className="footer-btn" onClick={this.props.acceptClickHandler}>
                    <div className="comment-icon accept" />
                    Accept Answer</div>}

                    {this.props.showEdit && <div className="footer-btn" onClick={this.props.editClickHandler}>
                        <div className="comment-icon edit-comment" />
                        Edit</div>}
                    {this.props.showReport && <div className="crimso footer-btn" onClick={this.props.reportClickHandler}>
                        <div className="comment-icon report-comment" />
                        Report</div>}
                </div>

            );
        } else {

            var likesBit = this.figureOutLikesBit();
            const cmntStr = this.props.commentCount == 1 ? "Comment" : "Comments";

            const cmntIcon = this.props.commentCount > 0 ? <div className="comment-icon full-comment" /> : <div className="comment-icon empty-comment" />

            return (
                <div className="footer">{likesBit} {cmntIcon} {this.props.commentCount} {cmntStr}</div>
            );
        }
    }

    likeTapped(e)
    {
        this.props.likeHandler(this.props.postID)
        e.stopPropagation();
        var newCount = this.state.likeCount
        if (this.state.liked)
        {
            newCount--;
        } else {
            newCount++;

        }
        this.setState({liked: ! this.state.liked, likeCount: newCount});

    }
}


export default PostFooter;
