import React from 'react'
import BackButton from "../common/BackButton"
import { API } from '../../api/APIClient'
import Axios from 'axios'


class Register extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            email: "",
            email2: "",
            password: "",
            password2: "",
            howHear: "",
            referCode: "",
            referDescr: "",
            fname: "",
            sname: "",
            marketing: "",
            tncs: ""
        };
        this.handleChange = this.handleChange.bind(this);

    }



    componentDidMount() {

        //not going to work on web... this.pullInReferral();
    }


    handleChange(event) {

        const target = event.target;
        const value = target.value;
        const name = target.name;

        if (name === "marketing" || name === "tncs")
        {
            const checked = target.checked;

            this.setState({
                [name]: checked
            });

        } else if (name === "referCode")
        {
            this.setState({
                [name]: value
            }, () => this.validateReferral());
        } else {
            this.setState({
                [name]: value
            });
        }

    }


    validateReferral()
    {
        if (this.state.referCode.length < 4)
        {
            this.setState({
                referDescr: ""
            })
        } else {
            const formData = new FormData();
            formData.append('code', this.state.referCode);

            Axios
                .post(API.referral.verify, formData)
                .then((res) => {

                    if (res.data.success && res.data.data.code) {

                        this.setState({
                            referDescr: "Referred by " + res.data.data.name + " (" + res.data.data.code + ")"

                        })
                    } else {
                        this.setState({
                            referDescr: ""
                        })
                    }

                })
        }


    }

    pullInReferral()
    {
        Axios
            .get(API.referral.lookup)
            .then((res) => {

                if (res.data.success && res.data.data.code) {

                    this.setState({
                        referCode: res.data.data.code,
                        referDescr: res.data.data.name + " (" + res.data.data.code + ")"

                    })
                }

            })
    }



    signUpClicked()
    {
        if (this.state.email.length > 0 && this.state.password.length > 0
            && this.state.howHear.length > 0 && this.state.fname.length > 0 && this.state.sname.length > 0)
        {

            if (this.state.password.length > 5)
            {
                if (this.state.password === this.state.password2)
                {
                    if (this.state.email === this.state.email2)
                    {
                        if (this.state.tncs)
                        {
                            //register!
                            const formData = new FormData();
                            formData.append('code', this.state.referCode);
                            formData.append('email', this.state.email);
                            formData.append('familyName', this.state.sname);
                            formData.append('givenName', this.state.fname);
                            formData.append('howHear', this.state.howHear);
                            formData.append('marketing', this.state.marketing);
                            formData.append('password', this.state.password);
                            formData.append('trialOffer', 1);

                            Axios
                                .post(API.auth.register, formData)
                                .then((res) => {

                                    if (res.data.success ) {

                                        var user = res.data.data.user;
                                        user.state = res.data.data.state;
                                        user.token = res.data.data.token;

                                        this.props.loginHandler(user);
                                    }

                                }).catch(error => {

                                    this.setState({
                                        submitting:false,
                                        submitMessage:""
                                    });

                                    console.log(error.response)
                                    if (error.response.data.error) {

                                        alert(error.response.data.error)
                                    } else{
                                        alert("Could not register.  Please check connection and try again.")

                                    }
                                });

                        } else {
                            alert("Please agree to the terms and conditions.")

                        }
                    }else {
                        alert("Email and Re-type Email fields do not match, please try again.")
                    }


                } else {
                    alert("Password and Re-type Password fields do not match, please try again.")
                }
            } else {
                alert("Password must be at least 6 characters long, please try again.")

            }


        } else {
            alert("Please enter your email, first name, surname, fill in the password fields, and select how you heard about Gas App Uk.")
        }

    }

    render(){
        return(
            <div className="loginMain">
                <div className="main-wrap">

                    <div className="cell small-12 header">
                        <BackButton backHandler={this.props.backHandler} />
                        
                    </div>
                    <div className="clearfix"></div> 

                    <div className="reg-box">
                        
                        <h1 className="head-title ">New User Registration</h1>
                        
                        <div className="main-wrap">
                            <div className="cell small-12">
                                <label>
                                    <span class="reg-label">Email:</span>
                                    <input className="reg-field" type="text" name="email" placeholder="Email" value={this.state.email} onChange={this.handleChange}/>
                                </label>
                            </div>

                            <div className="cell small-12">
                                <label>
                                    <span class="reg-label">Re-type Email:</span>
                                    <input className="reg-field" type="text" name="email2" placeholder="Email" value={this.state.email2} onChange={this.handleChange}/>
                                </label>
                            </div>

                            <div className="cell small-12">
                                <label>
                                    <span class="reg-label">Password:</span>
                                    <input className="reg-field" type="password" name="password" placeholder="Password" value={this.state.password} onChange={this.handleChange}/>
                                </label>
                            </div>

                            <div className="cell small-12">
                                <label>
                                    <span class="reg-label">Re-type Password:</span>
                                    <input className="reg-field" type="password" name="password2" placeholder="Password" value={this.state.password2} onChange={this.handleChange}/>
                                </label>
                            </div>

                            <div className="cell small-12">
                                <label>
                                    <span class="reg-label">How did you hear about Gas App Uk? :</span>
                                    <select className="reg-select" value={this.state.howHear} name="howHear" onChange={this.handleChange}>
                                        <option value="">-- Select --</option>
                                        <option value="Friend / Recommend">Friend / Recommend</option>
                                        <option value="Magazine">Magazine</option>
                                        <option value="Apple / Play Search">Apple / Play Search</option>
                                        <option value="Training Centre">Training Centre</option>
                                        <option value="Exhibition / Shows">Exhibition / Shows</option>
                                        <option value="Social Media">Social Media</option>
                                        <option value="Other">Other</option>
                                    </select>
                                </label>
                            </div>

                            <div className="cell small-12">
                                <label>
                                    <span className="reg-label">Referral code = who referred you?:</span>
                                    <input className="reg-field" type="text" name="referCode" placeholder="Referral Code" value={this.state.referCode} onChange={this.handleChange}/>
                                    <div>{this.state.referDescr}</div>
                                </label>
                            </div>

                            <div className="cell small-12">
                                <label>
                                    <span className="reg-label">First Name:</span>
                                    <input className="reg-field" type="text" name="fname" placeholder="First Name" value={this.state.fname} onChange={this.handleChange}/>
                                </label>
                            </div>
                            <div className="cell small-12">
                                <label>
                                    <span className="reg-label">Surname:</span>
                                    <input className="reg-field" type="text" name="sname" placeholder="Surname" value={this.state.sname} onChange={this.handleChange}/>
                                </label>
                            </div>

                            <div className="cell small-12">
                                <label>
                                    <span className="reg-label">Terms and Conditions:</span>
                                    <span className="reg-label tick-text"><input className="reg-field" type="checkbox" name="marketing" checked={this.state.marketing} onClick={this.handleChange}/>
                                        Please tick here if you would like to receive email marketing messages from us about our products and any special offers.</span>
                                </label>
                            </div>

                            <div className="cell small-12">
                                <label>
                                    <span className="reg-label tick-text"><input className="reg-field" type="checkbox" name="tncs" checked={this.state.tncs} onClick={this.handleChange}/>
                                        By ticking this box, you agree to comply with our Service Terms and Conditions.</span>
                                </label>
                            </div>
                            <div className="cell small-12">

                                <div onClick={() => this.signUpClicked()} className="ga-button">Next</div>

                            </div>
                        </div>
                        <div className="clearfix"></div>
                    </div>

                </div>

            </div>
            
            

        );
    }


}

export default Register;
