import React from 'react'
import Iframe from "../common/Iframe"
import BackButton from "../common/BackButton"
import { API } from '../../api/APIClient'

function ReferralStore(props) {

    let iframeSrc = API.BASE_URL + "/referral/shop/" + props.userID
    return(
    <div className="main-wrap">
    	<div className="cell small-12 terms-frame">
	    	<BackButton backHandler={props.backHandler} />
	        <Iframe src={iframeSrc} />
        </div>
    </div>
    );
}

export default ReferralStore;
