import React from 'react'
import Iframe from "../common/Iframe"
import BackButton from "../common/BackButton"

function Terms(props) {

    return(
    <div className="main-wrap">
    	<div className="cell small-12 terms-frame">
    		<BackButton backHandler={props.backHandler} />
    		<Iframe src="https://gasapp.app-cms.com/info/policy_index/" />
    	</div>
    </div>
    );
}

export default Terms;
