import React from 'react'
import BackButton from "../common/BackButton"
import { API } from '../../api/APIClient'
import Axios from 'axios'

class ForgotPassword extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            email:""
        };
        this.handleChange = this.handleChange.bind(this);

    }




    handleChange(event) {

        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    sendResetRequest()
    {
        if (this.state.email.length > 0 )
        {
            this.setState({
                submitting:true,
                submitMessage:"Sending request..."
            });
            const formData = new FormData();
            formData.append('email', this.state.email);
            Axios
                .post(API.auth.resetPassword, formData)
                .then((res) => {

                    this.setState({
                        submitting:false,
                        submitMessage:""
                    });
                    if (res.data.success) {

                        alert(res.data.data)

                        this.props.backHandler();

                    }

                }).catch(error => {

                    this.setState({
                        submitting:false,
                        submitMessage:""
                    });

                    console.log(error.response)
                    if (error.response.data.error) {

                        alert(error.response.data.error)
                    } else{
                        alert("Could not send request.  Please check connection and try again.")

                    }
                });

        } else {
            alert("Please enter your email address.")
        }
    }

    render(){
        return(
            <div className="loginMain">
                <div className="main-wrap">
                    <div className="cell small-12 header">
                        <BackButton backHandler={this.props.backHandler} />
                        
                    </div>
                    <div className="clearfix"></div>
                    <div className="reg-box">
                        <h1 className="head-title">Forgotten Password</h1>
                        <div className="main-wrap">
                            <div className="cell small-12">
                                <p>Having difficulties logging in?</p>
                                <p>Enter the email you signed up with above and you will receive instructions how to reset your password directly to your email inbox.</p>
                                <p>If you do not receive your email after a few minutes, please check your spam or junk folders.</p>
                            </div>
                            <div className="cell small-12">
                                <label>
                                    <span className="reg-label">Email:</span>
                                    <input className="reg-field" type="text" name="email" placeholder="Email" value={this.state.email} onChange={this.handleChange}/>
                                </label>
                            </div>
                            <div className="cell small-12">
                                <div className="ga-button" onClick={() => this.sendResetRequest()}>Submit</div>
                            </div>
                            <div className="clearfix"></div>
                        </div>
                    </div>
                </div>
            </div>

        );
    }


}

export default ForgotPassword;
