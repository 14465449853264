import React from 'react'
import Iframe from "../common/Iframe"
import BackButton from "../common/BackButton"
import { API } from '../../api/APIClient'

function Deal(props) {

    let iframeSrc = props.dealURL
    return(
        <div className="main-wrap mdframe">
            <div className="cell small-12 terms-frame">
                <BackButton backHandler={props.backHandler} />
                
                	<Iframe src={iframeSrc} />
                
            </div>
        </div>
    );
}

export default Deal;
