import React from 'react';
import Axios from "axios";
import {API, APIHelper} from "../../api/APIClient";
import moment from "moment";
import SegmentedButtonComponent from "../common/SegmentedButtonComponent";

import "../../assets/css/websites.css";
import SimpleAlertDialog, {ModalButton} from "../common/SimpleAlertDialog";
import {classNames} from "react-id-swiper/lib/utils";

class WebsiteReviews extends React.Component {

    constructor(props, context) {
        super(props, context);

        this.state = {
            page : 1,
            published : 0,
            totalCount : 0,
            reviews : [],
            toastMessage : null,
            alertModalOpen : false,
            alertModalTitle : null,
            alertModalMessage: null,
            alertModalButtons : []
        };

        this.getWebsiteReviewsFromNetwork = this.getWebsiteReviewsFromNetwork.bind(this);
        this.publishedCategoryChanged = this.publishedCategoryChanged.bind(this);
        this.showToast = this.showToast.bind(this);
    }

    componentDidMount() {
        this.isComponentMounted = true;

        this.getWebsiteReviewsFromNetwork();
    }

    componentWillUnmount() {
        this.isComponentMounted = false;

        if (this.toastTimeout !== undefined) {
            clearTimeout(this.toastTimeout);
        }
    }

    getWebsiteReviewsFromNetwork(published, page) {
        if (published === undefined) {
            published = this.state.published;
        }

        if (page === undefined) {
            page = this.state.page;
        }

        let formData = new FormData();
        formData.append("page", page);
        formData.append("published", published);

        Axios.post(API.websites.getUserWebsiteReviews, formData)
            .then((r) => {
                let resp = APIHelper.parse(r);
                if (resp.success) {
                    window.scrollTo(0,0);

                    this.setState({
                        totalCount : resp.data.totalCount,
                        reviews : resp.data.reviews
                    })
                } else {
                    console.log(resp.error);
                }
            })
            .catch((e) => {
                console.log(e);
            });
    }

    sendPublishRequestOverNetwork(id, published) {
        if (published === undefined) {
            published = 1;
        }

        let formData = new FormData();
        formData.append("userWebsiteReviewId", id);
        formData.append("published", published);

        Axios.post(API.websites.publishUserWebsiteReview, formData)
            .then((r) => {
                let resp = APIHelper.parse(r);
                if (resp.success) {
                    console.log("OK!");
                    // Update list
                    this.getWebsiteReviewsFromNetwork();

                    this.showToast("Review has been approved for display");
                } else {
                    console.log(resp.error);
                }
            })
            .catch((e) => {
                console.log(e);
            });
    }

    deleteReviewOverNetwork(id) {
        let formData = new FormData();
        formData.append("userWebsiteReviewId", id);

        Axios.post(API.websites.deleteUserWebsiteReview, formData)
            .then((r) => {
                let resp = APIHelper.parse(r);
                if (resp.success) {
                    console.log("OK!");
                    this.getWebsiteReviewsFromNetwork();

                    this.showToast("Review has been removed");
                } else {
                    console.log(resp.error);
                }
            })
            .catch((e) => {
                console.log(e);
            });
    }

    publishedCategoryChanged(id) {
        this.setState({
            published : id,
            page : 1
        });

        this.getWebsiteReviewsFromNetwork(id);
    }

    changePage(page) {
        this.setState({
            page : page
        });

        this.getWebsiteReviewsFromNetwork(undefined, page);
    }

    showToast(message) {
        if (this.toastTimeout !== undefined) {
            clearTimeout(this.toastTimeout);
        }

        this.setState({
            toastMessage : message
        });

        this.toastTimeout = setTimeout(() => {
            if (this.isComponentMounted) {
                this.setState({
                    toastMessage: null
                });
            }
        }, 6000);
    }

    showAlertModal(title, message, buttons) {
        this.setState({
            alertModalOpen : true,
            alertModalTitle : title,
            alertModalMessage : message,
            alertModalButtons : buttons
        });
    }

    hideAlertModal() {
        this.setState({
            alertModalOpen : false
        });
    }

    promptForReviewPublish(id) {
        this.showAlertModal(
            "Publish Review",
            "Are you sure you want to publish this review? It will be visible to the public immediately.",
            [
                {
                    label : "Publish Now",
                    onClick : () => { this.hideAlertModal(); this.sendPublishRequestOverNetwork(id); },
                    className : "success"
                },
                {
                    label : "Cancel",
                    onClick : () => { this.hideAlertModal() }
                }
            ]
        );
    }

    promptForReviewRemoval(id) {
        this.showAlertModal(
            "Remove Review",
            "Are you sure you want to remove this review? This will permenantly remove the review from your website. This action cannot be undone.",
            [
                {
                    label : "Remove",
                    onClick : () => { this.hideAlertModal(); this.deleteReviewOverNetwork(id); },
                    className : "danger"
                },
                {
                    label : "Cancel",
                    onClick : () => { this.hideAlertModal(); }
                }
            ]
        );
    }

    render() {
        let reviewElements = [];
        if (this.state.reviews.length > 0) {
            for (let i = 0; i < this.state.reviews.length; i++) {
                let review = this.state.reviews[i];

                let approveButton = [];
                if (review.published == 0) {
                    approveButton = (
                        <span className={"ga-button"} onClick={() => this.promptForReviewPublish(review.id)}>Approve and Publish</span>);
                }

                reviewElements.push(
                    <div className={"review-item"}>
                        <div className={"info-area"}>
                            <div className={"half-block"}>
                                <div><strong>{review.fullName}</strong></div>
                                <div>{review.location}</div>
                            </div>
                            <div className={"half-block text-right"}>
                                <div>{moment(review.dateCreated).format("DD/MM/YYYY")}</div>
                            </div>
                        </div>
                        <div className={"review-body"}>
                            {review.body}
                        </div>
                        <div className={"action-area"}>
                            {approveButton}
                            <span className={"ga-button ga-button-danger"} onClick={() => this.promptForReviewRemoval(review.id)}>Remove</span>
                        </div>
                    </div>
                );
            }
        } else {
            let label = "unpublished";
            if (this.state.published == 1) {
                label = "published";
            }

            reviewElements.push(
                <div className={"empty-message"}>
                    <h2>Nothing here</h2>
                    <em>There are no {label} reviews at this time. Encourage your customers to write you reviews.</em>
                </div>
            )
        }

        let pages = this.state.totalCount / 30;
        let pageElements = [];
        if (pages > 1) {
            for (let i = 0; i < pages; i++) {
                let selected = "";
                if ((i + 1) == this.state.page) {
                    selected = " selected";
                }
                pageElements.push(
                    <span className={"page-item" + selected} onClick={() => this.changePage(i + 1)}>{(i + 1)}</span>
                );
            }
        }

        let toastMessage = [];
        if (this.state.toastMessage != null) {
            toastMessage = (
                <div className={"toast-message"}>
                    <div className={"text"}>
                        {this.state.toastMessage}
                    </div>
                </div>
            );
        }

        return (
            <div className="main-wrap">
                <div className="cell small-12 header">
                    <h1 className="head-title websites">Reviews</h1>
                </div>

                <div className={"content-body"}>
                    <div className={"segmented-button-container"}>
                        <SegmentedButtonComponent
                            selectedId={this.state.published}
                            buttons={[
                                { id : 0, label : "Unpublished" },
                                { id : 1, label : "Published" }
                            ]}
                            changeHandler={this.publishedCategoryChanged} />
                    </div>

                    <div className={"pager"}>
                        {pageElements}
                    </div>

                    <div className={"website-reviews"}>
                        {reviewElements}
                    </div>

                    <div className={"pager"}>
                        {pageElements}
                    </div>
                </div>
                {toastMessage}

                <SimpleAlertDialog
                    open={this.state.alertModalOpen}
                    title={this.state.alertModalTitle}
                    message={this.state.alertModalMessage}>
                    {
                        this.state.alertModalButtons.map((button) => (
                            <ModalButton label={button.label} onClick={button.onClick} className={button.className} />
                        ))
                    }
                </SimpleAlertDialog>
            </div>
        );
    }
}

export default WebsiteReviews;