import React from 'react'
import { API } from '../../api/APIClient'
import Axios from 'axios'
import CompTerms from '../terms/CompTerms'
import moment from 'moment';

class Competition extends React.Component  {
    constructor(props) {
        super(props);

        this.SCREENS = {
            HOME : "home",
            TNCs : "terms"
        };
        this.state = {
            currentScreen: this.SCREENS.HOME,
            boxes : [],
            competition:null,
            userAnswer: null,
            selectedAnswer: null,
            navStack: [],
            isLoading: true,
            debug: {}
        }
        this.handleInputChange = this.handleInputChange.bind(this);

    }



    getCompetition = () => {

        Axios
            .get(API.competition.getInfo)
            .then((res) => {
                this.setState({
                    debug: res
                });
                if (res.data.success) {


                    if (res.data.data.userAnswer) {
                        this.setState({
                            userAnswer: res.data.data.userAnswer
                        })

                        const answers =  res.data.data.competition.answers.map((answer, c) => {

                            const checked = (res.data.data.userAnswer && res.data.data.userAnswer == answer.id);
                            return <label className="comp-ans-wrap"><input
                                name="answer"
                                type="radio"
                                checked={checked}
                            />{answer.title}<span className="radio-box"></span></label>;

                        });
                        this.setState({
                            competition: res.data.data.competition,
                            answers: answers
                        })
                    } else {

                        const answers =  res.data.data.competition.answers.map((answer, c) => {

                            return <label className="comp-ans-wrap"><input
                                name="answer"
                                value={answer.id}
                                type="radio"
                                onChange={(e) => this.handleInputChange(e)}
                            />{answer.title}<span className="radio-box"></span></label>;

                        });
                        this.setState({
                            competition: res.data.data.competition,
                            answers: answers
                        })
                    }


                }
            })
    }



    submitAnswer = () => {

        const formData = new FormData();
        formData.append('answerId', this.state.selectedAnswer);
        formData.append('competitionId', this.state.competition.id);

        Axios
            .post(API.competition.submitAnswer, formData)
            .then((res) => {


                if (res.data.success) {

                    this.setState({
                        userAnswer: this.state.selectedAnswer
                    })
                } else {

                    alert("Error sending answer");

                }
            })
    }


    handleInputChange(event) {

        const target = event.target;

        this.setState({
            selectedAnswer: target.value
        });
    }

    handleBackClick ()
    {
        const navStack = this.state.navStack.slice();

        if (navStack.length > 1)
        {
            this.props.showMenuHandler(false);
        } else {
            this.props.showMenuHandler(true);

        }
        const backScreen = navStack[navStack.length - 1];

        this.setState({
            currentScreen: backScreen,
            navStack: navStack.slice(0,navStack.length - 1)
        });

    }

    componentDidMount() {

        this.getCompetition();
        this.props.showMenuHandler(true);

    }


    handleTNCClick()
    {
        this.props.showMenuHandler(false);
        const oldScreen = this.state.currentScreen;
        const navStack = this.state.navStack.concat([oldScreen])
        this.setState({
            currentScreen: this.SCREENS.TNCs,
            navStack: navStack
        });
    }

    handleSubmit(){

        if (this.state.selectedAnswer){

            this.submitAnswer();

        } else {
            alert("Please select an answer");
        }
    }
    render() {

        switch (this.state.currentScreen) {

            case this.SCREENS.HOME :
                if (this.state.competition) {

                    let from =  moment(this.state.competition.dateStart, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY');
                    let until =  moment(this.state.competition.dateEnd, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY');
                    let draw =  moment(this.state.competition.drawDate, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY');


                    return (
                        <div className="main-wrap">
                            <div className="cell small-12 header">
                                <h1 className="head-title competition">Competition</h1>
                            </div>
                            <div className="clearfix"></div>
                            <div className="main-wrap square-white competition">
                                <div className="cell small-12 medium-6 comp-img">
                                <img src={this.state.competition.image} alt="Competition Prize" />
                                </div>
                                <div className="cell small-12 medium-6">
                                    <h2>{this.state.competition.title}</h2>
                                    <div className="bodyText">{this.state.competition.description}</div>
                                    
                                    <div className="bodyText">{this.state.competition.prizeDescription}</div>
                                    
                                    <div className="bodyText">The competition runs from {from} until {until}. The winner will be drawn on {draw}


                                    </div>
                                    <div className="comp-quest">
                                        <div className="bodyText">{this.state.competition.question}</div>
                                        {this.state.answers}
                                        {this.state.userAnswer ? <div className="bodyText thanks">Thank You! Your entry for this competition has been received.</div> : <div className="ga-button" onClick={() => this.handleSubmit()}>Enter Competition</div> }
                                    </div>
                                    <div className="tnc-text underline" onClick={() => this.handleTNCClick()}>By entering you agree to the terms & conditions</div>
                                </div>
                                <div className="clearfix"></div>

                            </div>
                        </div>
                    );
                } else {
                    return (<div>No competition found</div>);
                }
            case this.SCREENS.TNCs :
                return(<CompTerms backHandler={() => this.handleBackClick()}  />);

            default:
                return <div>unknown screen</div>
        }


    }
}

export default Competition;
