import React from 'react'
import PartnersList from './partners/PartnersList'
import ReferralsHome from './referrals/ReferralsHome'
import ToolboxHome from './toolbox/ToolboxHome'
import OfficeHome from './office/OfficeHome'
import Competition from './competition/Competition'
import BreaktimePosts from './breaktime/BreaktimePosts'
import FaultFindingPosts from './faultfinding/FaultFindingPosts'
import Newsroom from './newsroom/Newsroom'
import HorrorPosts from './horrorshow/HorrorPosts'
import TalentPosts from './talentshow/TalentPosts'
import MassiveDeals from './massivedeals/MassiveDeals'
import SettingsHome from './settings/SettingsHome'
import {SECTIONS} from '../resources/strings'
import {Route, Switch} from "react-router-dom";
import WebsiteLanding from "./websites/WebsiteLanding";
import WebsiteEditor from "./websites/WebsiteEditor";
import WebsiteReviews from "./websites/WebsiteReviews";
import WebsiteSettings from "./websites/WebsiteSettings";

const NotFound = () => <div>Page not found</div>;

class AppSection extends React.Component {


    render() {

        return (
                <Switch>

                    <Route path={'/'+ SECTIONS.PARTNERS} exact render={(props) => <PartnersList showMenuHandler={this.props.showMenuHandler}
                                                                                                logoutHandler={this.props.logoutHandler}
                                                                                        {...props} />}  />
                    <Route path={'/'+ SECTIONS.REFERRALS} exact render={(props) => <ReferralsHome showMenuHandler={this.props.showMenuHandler}
                                                                                                    user={this.props.user}
                                                                                                {...props} />}  />
                    <Route path={'/'+ SECTIONS.TOOLBOX} exact  render={(props) => <ToolboxHome showMenuHandler={this.props.showMenuHandler} {...props} />}  />
                    <Route path={'/'+ SECTIONS.OFFICE} exact render={(props) => <OfficeHome showMenuHandler={this.props.showMenuHandler}
                                                                                    user={this.props.user} {...props}/>}  />
                    <Route path={'/'+ SECTIONS.COMPETITION} exact render={(props) => <Competition showMenuHandler={this.props.showMenuHandler} {...props} />} />
                    <Route path={'/'+ SECTIONS.BREAK_TIME} exact render={(props) => <BreaktimePosts showMenuHandler={this.props.showMenuHandler}
                                                                                                    user={this.props.user}
                                                                                                {...props} />}  />
                    <Route path={'/'+ SECTIONS.FAULT_FINDING} exact render={(props) => <FaultFindingPosts
                                                                                        showMenuHandler={this.props.showMenuHandler}
                                                                                        user={this.props.user}
                                                                                        {...props} />}  />
                    <Route path={'/'+ SECTIONS.NEWSROOM} exact  render={(props) => <Newsroom showMenuHandler={this.props.showMenuHandler} {...props} />}  />
                    <Route path={'/'+ SECTIONS.HORROR_SHOW} exact render={(props) => <HorrorPosts showMenuHandler={this.props.showMenuHandler} {...props} />} />
                    <Route path={'/'+ SECTIONS.TALENT_SHOW} exact render={(props) => <TalentPosts showMenuHandler={this.props.showMenuHandler} {...props} />} />
                    <Route path={'/'+ SECTIONS.MASSIVE_DEALS} exact render={(props) => <MassiveDeals showMenuHandler={this.props.showMenuHandler} {...props} />}/>
                    <Route path={'/'+ SECTIONS.SETTINGS} exact render={(props) => <SettingsHome showMenuHandler={this.props.showMenuHandler}
                                                                                                logoutHandler={this.props.logoutHandler}
                                                                                                user={this.props.user} {...props} />} />

                    <Route path={"/" + SECTIONS.WEBSITES} exact
                        render={(props) => <WebsiteLanding user={this.props.user} {...props} />}
                        forceReg={true} />

                    <Route path={"/" + SECTIONS.WEBSITES + "/edit"}
                           render={(props) => <WebsiteEditor {...props} /> }
                           forceReg={true} />

                    <Route path={"/" + SECTIONS.WEBSITES + "/reviews"}
                           render={(props) => <WebsiteReviews {...props} /> }
                           forceReg={true} />

                    <Route path={"/" + SECTIONS.WEBSITES + "/settings"}
                           render={(props) => <WebsiteSettings />}
                           forceReg={true} />

                    <Route path={'/'} render={(props) => <PartnersList
                    logoutHandler={this.props.logoutHandler}
                    showMenuHandler={this.props.showMenuHandler} {...props} />}  />
                    <Route component={NotFound} />

                </Switch>

        );
    }

}

export default AppSection;
