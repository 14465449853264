import React from 'react'
import PostUserInfo from '../sharedBreakFault/PostUserInfo'
import PostImages from '../sharedBreakFault/PostImages'
import PostFooter from '../sharedBreakFault/PostFooter'
import { API } from '../../api/APIClient'
import Axios from 'axios'



class MainPost extends React.Component {

    constructor(props) {
        super(props);


        this.state = {
            isEditing: false,
            isReplying: false,
            editText: this.props.post.body,
            replyText: ""

        };

        this.fileChangedHandler = this.fileChangedHandler.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }


    fileChangedHandler = (event) => {
        const file = event.target.files[0]

        if (file.name.length > 4)
        {
            var last3 = file.name.substr(file.name.length - 3).toUpperCase();
            var last4 = file.name.substr(file.name.length - 4).toUpperCase();

            if (last4 === "JPEG" || last3 === "JPG" || last3 === "PNG")
            {
                const name = event.target.name;

                this.setState({
                    [name]: file
                });
            } else {
                alert('Please choose an image file (jpg or png)');
            }

        } else {
            alert('Please choose an image file (jpg or png)');
        }

    }

    replyClicked()
    {
        const replying = !this.state.isReplying
        this.setState({
            isReplying : replying,
            submitting: false
        });
    }
    submitReplyClicked(){

        if (this.props.user.state == 2) //trial user
        {
            alert("You must be a paid-subscription user to create Breaktime posts.");

        } else {
            this.setState({
                submitting:true,
                submitMessage:"Sending reply..."
            });
            const formData = new FormData();
            formData.append('postId', this.props.post.id);
            formData.append('body', this.state.replyText);

            Axios
                .post(API.breakTime.submitComment, formData)
                .then((res) => {

                    if (res.data.success) {

                        var imagesToUpload = [];
                        if (this.state.image1) {imagesToUpload.push(this.state.image1);}
                        if (this.state.image2) {imagesToUpload.push(this.state.image2);}
                        if (this.state.image3) {imagesToUpload.push(this.state.image3);}
                        if (this.state.image4) {imagesToUpload.push(this.state.image4);}

                        if (imagesToUpload.length > 0)
                        {
                            this.setState(
                                {imagesToUpload: imagesToUpload,
                                    postID: res.data.data
                                }
                            );
                            this.uploadNextimage();

                        } else {
                            this.doneReplying();
                        }

                    } else {

                        alert("Could not send reply.  Please check connection and try again.")
                    }
                })

        }

    }


    uploadNextimage()
    {
        this.setState({
            submitting:true,
            submitMessage:"Uploading images, " + this.state.imagesToUpload.length  + " remaining..."
        });

        if (this.state.imagesToUpload.length > 0)
        {
            const formData = new FormData();
            formData.append('postId', this.state.postID);
            formData.append('image', this.state.imagesToUpload[0], this.state.imagesToUpload[0].name);

            Axios
                .post(API.breakTime.uploadCommentImage, formData)
                .then((res) => {

                    const newimgArr = this.state.imagesToUpload.slice(1);
                    this.setState({
                        imagesToUpload:newimgArr
                    });
                    this.uploadNextimage();

                })
        } else {
            this.doneReplying();
        }



    }


    doneReplying()
    {
        this.setState({
            isReplying:false,
            submitting:false,
            updatedText: this.state.editText,
            submitMessage:""
        });
        this.props.refreshHandler()
    }



    editClicked()
    {
        const editing = !this.state.isEditing
        this.setState({
            isEditing : editing,
            submitting: false
        });
    }

    reportClicked()
    {
        const formData = new FormData();
        formData.append('post', this.props.post.id);
        Axios
            .post(API.breakTime.reportPost, formData)
            .then((res) => {

                if (res.data.success) {


                } else {


                }
            })
        alert("Thanks! This post has been reported.");

    }

    submitEditClicked(){
        this.setState({
            submitting:true,
            submitMessage:"Saving..."
        });
        const formData = new FormData();
        formData.append('postId', this.props.post.id);
        formData.append('body', this.state.editText);

        Axios
            .post(API.breakTime.editPost, formData)
            .then((res) => {

                if (res.data.success) {

                    this.setState({
                        isEditing:false,
                        submitting:false,
                        updatedText: this.state.editText,
                        submitMessage:""
                    });
                } else {

                    this.setState({
                        isEditing:false,
                        submitting:false,
                        submitMessage:""
                    });
                }
            })


    }


    handleChange(event) {

        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }




    render()
    {

        const bodyTxt = this.state.updatedText ? this.state.updatedText : this.props.post.body
        const editButtons  = this.state.submitting ? <div>{this.state.submitMessage}</div>
            :
            <div className="button-group"><div onClick={() => this.submitEditClicked()} className="ga-button">Done</div>
                <div onClick={() => this.editClicked()} className="ga-button">Cancel</div></div>

        const replyButtons  = this.state.submitting ? <div>{this.state.submitMessage}</div>
            :
            <div className="button-group"><div onClick={() => this.submitReplyClicked()} className="ga-button">Post</div>
                <div onClick={() => this.replyClicked()} className="ga-button">Cancel</div></div>

        const bodySection = this.state.isEditing ? <div className="edit-box"><label>
            <span className="edit-box-label">Message Text:</span>
            <textarea type="text" name="editText" placeholder="Message Text" value={this.state.editText} onChange={this.handleChange}/>
        </label></div>

            :  <div className="body">{bodyTxt}</div>


        return(
            <div className="cell small-12 ">
                <div className="post-ff">

                    <PostUserInfo profileImageThumb={this.props.post.profileImageThumb}
                        profileImage={this.props.post.profileImage}
                        givenName={this.props.post.givenName}
                        familyName={this.props.post.familyName}
                        isInstaller={this.props.post.isInstaller}
                        isBreakdown={this.props.post.isBreakdown}
                        isMisc={this.props.post.isMisc}
                        imageHandler={this.props.imageHandler}
                        isApprentice={this.props.post.isApprentice}
                        isTrainingCentreUser={this.props.post.isTrainingCentreUser}
                        hasValidGasCard={this.props.post.hasValidGasCard}
                        dateCreated={this.props.post.dateCreated} />


                {bodySection}
                        {this.state.isEditing && editButtons}

                    <PostImages images={this.props.post.images}
                        imageHandler={this.props.imageHandler}
                    />

                    <PostFooter showReply={true} showEdit={this.props.post.own_post == 1 ? true : false} showReport={this.props.post.own_post == 1 ? false : true}
                        likeCount={this.props.post.likeCount}
                        likeHandler={this.props.likeHandler}
                                editClickHandler={() => this.editClicked()}
                                reportClickHandler={() => this.reportClicked()}
                        replyClickHandler={() => this.replyClicked()}
                        postID={this.props.post.id}
                        liked={this.props.post.liked}
                        commentCount={this.props.post.commentCount} />
                    {this.state.isReplying && <div className="edit-box"><label>
                        <span className="edit-box-label">Reply:</span>
                        <textarea type="text" name="replyText" placeholder="Write a comment..." value={this.state.replyText} onChange={this.handleChange}/>
                    </label>
                        <input class="file-btn" type="file" name="image1" id="uplimg1" onChange={this.fileChangedHandler} accept=".jpg,.jpeg,.png"/>
                        <input class="file-btn" type="file" name="image2" id="uplimg2" onChange={this.fileChangedHandler} accept=".jpg,.jpeg,.png"/>
                        <input class="file-btn" type="file" name="image3" id="uplimg3" onChange={this.fileChangedHandler} accept=".jpg,.jpeg,.png"/>
                        <input class="file-btn" type="file" name="image4" id="uplimg4" onChange={this.fileChangedHandler} accept=".jpg,.jpeg,.png"/>
                    </div>}
                    {this.state.isReplying && replyButtons}
                </div>
            </div>
        );
    }

}

export default MainPost;
