import React from 'react';
import Axios from "axios";
import {API, APIHelper} from "../../api/APIClient";
import LoadingSpinner from "../common/LoadingSpinner";
import moment from "moment";
import SimpleAlertDialog, {ModalButton} from "../common/SimpleAlertDialog";
import {SECTIONS} from "../../resources/strings";

class WebsiteSettings extends React.Component {

    constructor(props, context) {
        super(props, context);

        this.state = {
            subscriptionNetworkInFlight : false,
            subscription : null,
            modalOpen : false,
            modalTitle : "",
            modalMessage : "",
            modalButtons : []
        }

        this.getWebsiteSubscriptionFromNetwork = this.getWebsiteSubscriptionFromNetwork.bind(this);
        this.showAlertModal = this.showAlertModal.bind(this);
        this.dismissAlertModal = this.dismissAlertModal.bind(this);
        this.promptForCancellationConfirmation = this.promptForCancellationConfirmation.bind(this);
        this.sendSubscriptionCancellationOverNetwork = this.sendSubscriptionCancellationOverNetwork.bind(this);
    }

    componentDidMount() {
        this.getWebsiteSubscriptionFromNetwork();
    }

    getWebsiteSubscriptionFromNetwork() {
        this.setState({
            subscriptionNetworkInFlight : true
        });

        Axios.get(API.websites.getWebsiteSubscription)
            .then((r) => {
                let subscription = null;

                let resp = APIHelper.parse(r);
                if (resp.success) {
                    subscription = resp.data.subscription;
                } else {
                    console.log(resp.error);
                }

                this.setState({
                    subscriptionNetworkInFlight : false,
                    subscription : subscription
                });
            })
            .catch((e) => {
                this.setState({
                    subscriptionNetworkInFlight : false
                });
            });
    }

    promptForCancellationConfirmation() {
        this.showAlertModal(
            "Confirm Cancellation",
            (<span>Please confirm you wish to cancel your Websites Subscription.<br/><br/>Your Website will remain accessible until the end of your Subscription Period and will become unavailable on <strong>{moment(this.state.subscription.periodEnd).format("DD/MM/YYYY HH:mm")}</strong>.</span>),
            [
                { label : "Cancel Subscription", className : "danger", onClick : () => {this.sendSubscriptionCancellationOverNetwork()}},
                { label : "Do not cancel", onClick : () => {this.dismissAlertModal()}}
            ]
        );
    }

    sendSubscriptionCancellationOverNetwork() {
        this.dismissAlertModal();
        this.setState({
            subscriptionNetworkInFlight : true
        });

        let formData = new FormData();
        formData.append("subscriptionId", this.state.subscription.id);

        Axios.post(API.websites.cancelSubscription, formData)
            .then((r) => {
                let subscription = this.state.subscription;

                let resp = APIHelper.parse(r);
                if (resp.success) {
                    subscription = resp.data.subscription;

                    this.showAlertModal(
                        "Success",
                        (<div>Your Subscription has been successfully cancelled. Your Website will become unavailable on {moment(subscription.periodEnd).format("DD/MM/YYYY HH:mm")}. You may re-activate your Subscription at any time and restore your website after this date.</div>),
                        [
                            { label : "OK", onClick : () => this.dismissAlertModal()}
                        ]
                    );
                } else {
                    console.log(resp.error);

                    this.showAlertModal("Error", resp.error, [
                        { label : "OK", onClick : () => this.dismissAlertModal() }
                    ]);
                }

                this.setState({
                    subscriptionNetworkInFlight : false,
                    subscription : subscription
                });
            })
            .catch((e) => {
                this.setState({
                    subscriptionNetworkInFlight : false
                });

                this.showAlertModal("Error", "An unknown error has occurred. Please try again later. [UWSC120]", [
                    { label : "OK", onClick : () => this.dismissAlertModal() }
                ]);
            })
    }

    showAlertModal(title, message, buttons) {
        this.setState({
            modalOpen : true,
            modalTitle : title,
            modalMessage : message,
            modalButtons : buttons
        });
    }

    dismissAlertModal() {
        this.setState({
            modalOpen : false
        });
    }

    render() {
        let subscriptionElement = [];
        if (this.state.subscriptionNetworkInFlight) {
            subscriptionElement = (
                <div className={"text-center"}>
                    <LoadingSpinner />
                </div>
            );
        } else {
            if (this.state.subscription != null) {
                let cancelButton = (<span className={"ga-button danger"} onClick={this.promptForCancellationConfirmation}>Cancel Subscription</span>);

                let subBadgeExtraClass = "";
                if (this.state.subscription.lastStatus === "active") {
                    subBadgeExtraClass = " success";
                } else if (this.state.subscription.lastStatus === "cancelled" || this.state.subscription.lastStatus === "canceled") {
                    subBadgeExtraClass = " danger";
                    cancelButton = [];
                }

                subscriptionElement = (
                    <div className={"subscription-info"}>
                        <div className={"sub-title"}>Your subscription</div>
                        <div className={"sub-info-item"}><strong>Status:</strong> <span className={"sub-badge" + subBadgeExtraClass}>{this.state.subscription.lastStatus}</span></div>
                        <div className={"sub-info-item"}><strong>Renewal Date:</strong> <span>{moment(this.state.subscription.periodEnd).format("DD/MM/YYYY")}</span></div>
                        <div className={"sub-actions"}>
                            {cancelButton}
                        </div>
                    </div>
                );
            } else {
                subscriptionElement = (
                    <div className={"subscription-info"}>
                        <div className={"sub-title"}>No Active Subscription</div>
                        <div className={"sub-info-item"}>Something may have gone wrong. We could not find an active Websites Subscription for your account. Please reload the page and try again. If the problem persists please contact support.</div>
                    </div>
                )
            }
        }

        return (
            <div>
                <div className="main-wrap">
                    <div className="cell small-12 header">
                        <h1 className="head-title websites">Websites</h1>
                    </div>

                    <div className={"content-body"}>
                        <h2>Settings</h2>

                        {subscriptionElement}

                        <div style={{ marginTop : "20px" }}>
                            <span className={"ga-button"} onClick={() => window.location.href = "/" + SECTIONS.WEBSITES}>Return to Websites</span>
                        </div>
                    </div>
                </div>

                <SimpleAlertDialog
                    open={this.state.modalOpen}
                    title={this.state.modalTitle}
                    message={this.state.modalMessage}>
                    {
                        this.state.modalButtons.map((button) => (
                            <ModalButton label={button.label} onClick={button.onClick} className={button.className} />
                        ))
                    }
                </SimpleAlertDialog>
            </div>
        );
    }

}

export default WebsiteSettings;