 import React from 'react'
import { API } from '../../api/APIClient'
import Axios from 'axios'
import BackButton from "../common/BackButton"
 import Lightbox from 'lightbox-react';
 import 'lightbox-react/style.css';

class HorrorDetail extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            userRating:this.props.horror.userRating,
            submitStars: this.generateSubmitStars(this.props.horror.userRating),
            lightboxOpen: false,
            imageIDX:0,
            submitted: false,
            avgRating: this.props.horror.rating
        }

    }


    imageClick(images)
    {
        if (images.length > 0)
        {
            this.setState({ligtboxImages: images, lightboxOpen:true})

        }
    }


    submitRating()
    {
        var ratingToSend = this.state.userRating;

        if (ratingToSend < 1)
        {
            ratingToSend=1;
        }

        const formData = new FormData();
        formData.append('postId', this.props.horror.id);
        formData.append('rating', ratingToSend);

        Axios
            .post(API.horror.submitRating, formData)
            .then((res) => {


                if (res.data.success) {

                    this.setState({
                        submitted: true,
                        avgRating: res.data.data
                    })
                    alert("Thanks, your rating has been received");
                } else {

                    alert("Error sending rating");

                }
            })


    }

    generateSubmitStars(rating)
    {
        var tmpStars = [];
        for (let c=1; c<=5; c++)
        {
            if (rating >= c)
            {
                tmpStars.push(<div className="full-star small-star" onClick={() => this.starTapped(c)}></div>);
            } else {
                tmpStars.push(<div className="empty-star small-star" onClick={() => this.starTapped(c)}></div>);

            }
        }
        return tmpStars
    }

    starTapped(starNum)
    {
        this.setState ({
            userRating:starNum,
            submitStars:this.generateSubmitStars(starNum)

        });
    }

    componentDidMount() { 


    }

    render() {

        var stars = [];
        for (var c=1; c<=5; c++)
        {
            if (this.state.avgRating >= c)
            {
                stars.push(<div className="full-star small-star" ></div>);
            } else {
                stars.push(<div className="empty-star small-star" ></div>);

            }
        }

        var profileImg = "";
        var lbImgs = [];
        if (this.props.horror.profileImageThumb !== null && this.props.horror.profileImageThumb.length > 0 )
        {
            profileImg = <img src={API.BASE_URL + this.props.horror.profileImageThumb} width="32" alt="Profile Thumb" />;
            lbImgs.push(API.BASE_URL + this.props.horror.profileImage);

        } else {
            profileImg = <div className="placeholder-icon" />;

        }


        return(

            <div className="main-wrap">
                <BackButton backHandler={this.props.backHandler} />
                <div className="clearfix"></div>
                <div class="horror-detail">

                  <div className="cell small-12 medium-7 no-pad">
                    <div className="horror-img">
                        <img src={this.props.horror.image.image} alt="Horror Show Submission" />
                    </div>
                  </div>

                  <div className="cell small-12 medium-5">
                    <div className="main-wrap">
                        <div className="horror-info">
                            <div className="cell small-7 horror-prof">
                                <div className="prof" onClick={() => this.imageClick(lbImgs)}
                                >{profileImg}</div>
                                <div className="details">
                                  <div className="name">{this.props.horror.givenName + " " + this.props.horror.familyName}</div>
                                  <div className="loc">{this.props.horror.location}</div>
                                </div>
                            </div>
                            <div className="cell small-5 rating">
                                <div className="label">Average user rating</div>
                                <div className="horror-rating">{stars}</div>
                            </div>
                            <div className="cell small-12 desc">
                              <div className="description">{this.props.horror.description}</div>
                            </div>
                            <div className="clearfix"></div>
                        </div>
                        <div className="horror-submit-rating">

                            <div className="title-text">Submit a rating</div>
                            <div className="submit-rating">{this.state.submitStars}</div>
                            <div className="faces"><div className="notsobad" /><div className="horrendous"/></div>

                        </div>
                        <div onClick={() => this.submitRating()} className="ga-button">Submit a rating</div>
                    </div>
                  </div>

                    <div className="clearfix"/>
                </div>
                {this.state.lightboxOpen && <Lightbox
                    mainSrc={this.state.ligtboxImages[this.state.imageIDX]}
                    nextSrc={this.state.ligtboxImages[(this.state.imageIDX + 1) % this.state.ligtboxImages.length]}
                    prevSrc={this.state.ligtboxImages[(this.state.imageIDX + this.state.ligtboxImages.length - 1) % this.state.ligtboxImages.length]}
                    onCloseRequest={() => this.setState({ lightboxOpen: false })}
                    onMovePrevRequest={() =>
                        this.setState({
                            imageIDX: (this.state.imageIDX + this.state.ligtboxImages.length - 1) % this.state.ligtboxImages.length
                        })
                        }
                    onMoveNextRequest={() =>
                        this.setState({
                            imageIDX: (this.state.imageIDX + 1) % this.state.ligtboxImages.length
                        })
                        }
                />}
            </div>
        );

    }
}

export default HorrorDetail;
