import React from 'react';

import "../../assets/css/simple-alert-dialog.css";

class SimpleAlertDialog extends React.Component {


    constructor(props, context) {
        super(props, context);
    }

    render() {
        let modalTitle = "";
        let modalMessage = "";

        let classExtra = "";
        if (this.props.open !== undefined) {
            if (this.props.open) {
                classExtra = " open";
            }
        }

        if (this.props.title !== undefined) {
            modalTitle = this.props.title;
        }

        if (this.props.message !== undefined) {
            modalMessage = this.props.message;
        }

        let buttonElements = [];
        if (this.props.children !== undefined && this.props.children != null) {
            React.Children.map(this.props.children, function(child) {
                if (child.type == ModalButton) {
                    let label = "";
                    let click = null;
                    let className = "";

                    if (child.props.label !== undefined) {
                        label = child.props.label;
                    }

                    if (child.props.onClick !== undefined) {
                        click = child.props.onClick;
                    }

                    if (child.props.className != null) {
                        className = " " + child.props.className;
                    }

                    buttonElements.push(
                        <div className={"modal-button" + className} onClick={click}>{ label }</div>
                    );
                }
            });
        }

        return (
            <div className={"simple-alert" + classExtra}>
                <div className={"alert-padding"} />
                <div className={"alert-modal"}>
                    <div className={"modal-title"}>{modalTitle}</div>
                    <div className={"modal-message"}>{modalMessage}</div>
                    { buttonElements }
                </div>
                <div className={"alert-padding"} />
            </div>
        )
    }

}

export default SimpleAlertDialog;

class ModalButton extends React.Component {}

export { ModalButton };