import React from 'react'
import SectionButton from '../common/SectionButton'
import Deal from './Deal'
import { API } from '../../api/APIClient'
import Axios from 'axios'

class MassiveDeals extends React.Component {

    constructor(props) {
        super(props);

        this.SCREENS = {
            HOME : "home",
            DEAL : "deal"
        };

        this.state = {
            currentScreen: this.SCREENS.HOME,
            navStack: [],
            deals: [],
            page: 1

        };
    }


    handleDealClick(url) {

        this.props.showMenuHandler(false);
        const oldScreen = this.state.currentScreen;
        const navStack = this.state.navStack.concat([oldScreen])
        this.setState({
            currentScreen: this.SCREENS.DEAL,
            dealURL: url,
            navStack: navStack
        });
    }

    handleBackClick ()
    {
        const navStack = this.state.navStack.slice();

        if (navStack.length > 1)
        {
            this.props.showMenuHandler(false);
        } else {
            this.props.showMenuHandler(true);

        }
        const backScreen = navStack[navStack.length - 1];

        this.setState({
            currentScreen: backScreen,
            navStack: navStack.slice(0,navStack.length - 1)
        });

    }
    getDeals = () => {

        const formData = new FormData();
        if (this.state.page > 1)
        {
            formData.append('page', this.state.page);
        }
        formData.append('typeId', 1);

        Axios
            .post(API.massive.getDeals, formData)
            .then((res) => {
                this.setState({
                    debug: res
                });
                if (res.data.success) {

                    const newDeals =  res.data.data.map((deal, c) => {

                        return <SectionButton title={deal.title} iconImage={deal.image} sectionClick={(url) => this.handleDealClick(deal.url)} />
                        // <SectionButton title="Reference PDFs" sectionClick={() => this.handleListItemClick(this.SCREENS.REFERENCE_PDFS)} iconClass="icon-reference"/>

                    });

                    if (newDeals.length > 0)
                    {
                        this.setState({
                            deals: this.state.deals.concat(newDeals),
                            page: this.state.page + 1
                        }, () => this.getDeals());
                    }



                }
            })
    }


    componentDidMount() {

        this.getDeals();
        this.props.showMenuHandler(true);

    }



    render () {

        switch (this.state.currentScreen) {

            case this.SCREENS.HOME :
                return (
                    <div className="main-wrap">
                        <div className="cell small-12 header">
                            <h1 className="head-title essentials">Massive Deals</h1>
                        </div>
                        <div className="cell small-12 deals-list">
             {this.state.deals ? this.state.deals : <div>Loading....</div>}
                        </div>
                    </div>
                );

            case
            this.SCREENS.DEAL
            :
                return (<Deal backHandler={() => this.handleBackClick()} dealURL={this.state.dealURL}  />);

            default :
                return (<div>unknown screen</div>);
        }
    }



}

export default MassiveDeals;
