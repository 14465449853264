import React from 'react'

function VideoCell(props) {

    return(
            <div className="video-cell" onClick={() => props.showVideoHandler(props.videoID)} >
                <div className="vid-img"><img src={props.thumbnailUrl} alt="Video Thumbnail" /></div>
                <div className="vid-title"><p>{props.title}</p></div>
            </div>
    );


}

export default VideoCell;
